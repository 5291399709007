<template>
  <div class="user_settings-content">
    <h3 class="content__heading">Настройки аккаунта</h3>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "user-settings",
  extends: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.user_settings-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 30px;
}
</style>
