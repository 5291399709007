import MainPage from "@/pages/MainPage";
import AddServerPage from "@/pages/AddServerPage";
import RegEmailPage from "@/pages/RegEmailPage";
import ConfirmRegPage from "@/pages/ConfirmRegPage";
import ServerSettingsPage from "@/pages/ServerSettingsPage";
import ServerPage from "@/pages/ServerPage";
import AccountPage from "@/pages/AccountPage";
import NotFoundPage from "@/pages/NotFoundPage";
import VotePage from "@/pages/VotePage";
import ServerCreatePage from "@/pages/ServerCreatePage";
import LoginPage from "@/pages/LoginPage";
import AccountRecoveryPage from "@/pages/AccountRecoveryPage";
import PasswordRecoveryPage from "@/pages/PasswordRecoveryPage";
import PasswordChangePage from "@/pages/PasswordChangePage";
import { createRouter, createWebHistory } from "vue-router";
import { checkTokenValidity } from "@/middleware";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundPage,
  },
  {
    path: "/",
    name: "Home",
    component: MainPage,
  },
  {
    path: "/servers/:id",
    name: "Servers",
    component: ServerPage,
  },
  {
    path: "/server-add",
    name: "ServerAdd",
    component: AddServerPage,
  },
  {
    path: "/reg-email",
    name: "RegEmail",
    component: RegEmailPage,
  },
  {
    path: "/reg",
    name: "Reg",
    component: ConfirmRegPage,
  },
  {
    path: "/account",
    name: "Account",
    component: AccountPage,
    beforeEnter: checkTokenValidity,
  },
  {
    path: "/server-settings/:id",
    name: "ServerSettings",
    component: ServerSettingsPage,
    beforeEnter: checkTokenValidity,
  },
  {
    path: "/vote/:id",
    name: "Vote",
    component: VotePage,
  },
  {
    path: "/server-create",
    name: "ServerCreate",
    component: ServerCreatePage,
    beforeEnter: checkTokenValidity,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/account-recovery",
    name: "AccountRecovery",
    component: AccountRecoveryPage,
  },
  {
    path: "/recovery_password",
    name: "PasswordRecovery",
    component: PasswordRecoveryPage,
  },
  {
    path: "/password-change",
    name: "PasswordChange",
    component: PasswordChangePage,
    beforeEnter: checkTokenValidity,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(/*to, from, savedPosition*/) {
    return { top: 0 };
  },
});

export default router;
