<template>
  <div class="container">
    <div class="server_settings">
      <div class="settings-top">
        <h3 class="heading">Создание сервера</h3>
      </div>
      <form class="settings-form" @submit.prevent="postData">
        <!-- IP-адрес -->
        <div class="settings-block ip-block">
          <div class="block__info">
            <span class="block__title">IP-адрес</span>
            <p class="block__desc">IP-адрес временно изменить нельзя</p>
          </div>
          <base-input
            class="input ip__input"
            type="text"
            placeholder="IP сервера"
            v-model="serverAddress"
            disabled
          />
        </div>
        <!-- Название сервера -->
        <div class="settings-block name-block">
          <div class="block__info">
            <span class="block__title">Название</span>
            <p class="block__desc">
              В названии сервера разрешено использовать буквы и цифры
            </p>
          </div>
          <base-input
            class="input name__input"
            type="text"
            placeholder="Название сервера"
            v-model="serverData.name"
          />
        </div>
        <!-- Краткое описание -->
        <div class="settings-block short_desc-block">
          <div class="block__info">
            <span class="block__title">Слоган</span>
            <p class="block__desc">
              Краткое опишите преимущества вашего проекта. Слоган отображается
              сразу под названием сервера. Максимум 64 символа
            </p>
          </div>
          <textarea
            class="textare short_description__textarea"
            cols="50"
            rows="3"
            v-model="serverData.short_description"
            @input="checkMaxLength"
            placeholder="Краткое описание сервера"
          ></textarea>
        </div>
        <!-- Баннер -->
        <div class="settings-block banner-block">
          <div class="block__info">
            <span class="block__title">Баннер</span>
            <p class="block__desc">
              Баннер сервера. Размер 468х60 пикселей. Поддерживаемые форматы:
              PNG JPG GIF
            </p>
          </div>
          <div class="banner">
            <!-- https://vuejsexamples.com/mobile-friendly-picture-file-input-vue-js-component-with-image-preview/ -->
            <picture-input
              ref="pictureInput"
              width="468"
              height="120"
              accept="image/jpeg,image/png,image/gif"
              size="5"
              :crop="false"
              :hideChangeButton="true"
              :custom-strings="{
                fileType: 'This file type is not supported.',
                fileSize: 'The file size exceeds the limit',
                drag: 'Нажми или перетяни',
              }"
              @change="onChange"
            >
            </picture-input>
          </div>
        </div>
        <!-- Версия игры -->
        <div class="settings-block version-block">
          <div class="block__info">
            <span class="block__title">Версия</span>
            <p class="block__desc">
              Версия клиента с которой игроки могут зайти и играть на вашем
              сервере
            </p>
          </div>
          <div class="version__box" v-if="!dataIsLoading">
            <select
              class="select version__select"
              name=""
              id=""
              v-model="serverData.version_from"
            >
              <option
                class="option"
                v-for="version in gameVersions"
                :key="version.id"
                :value="version.version"
              >
                {{ version.version }}
              </option>
            </select>
            <span class="version_to--mobile" v-show="!isOneVersion">-</span>
            <base-button
              class="version__btn"
              @click="showVersionTo"
              v-if="isOneVersion"
              >Поддерживается несколько версий</base-button
            >
            <div class="version_to__box" v-else>
              <span class="version_to">-</span>
              <select
                class="select version__select"
                name=""
                id=""
                v-model="serverData.version_to"
              >
                <option
                  class="option"
                  v-for="version in filteredGameVersionsTo"
                  :key="version.id"
                  :value="version.version"
                >
                  {{ version.version }}
                </option>
              </select>

              <base-button
                class="version__btn version__btn_desktop"
                @click="hideVersionTo"
                >Поддерживается одна версия</base-button
              >
            </div>
          </div>
          <base-button
            class="version__btn version__btn_mobile"
            @click="hideVersionTo"
            v-show="!isOneVersion"
            >Поддерживается одна версия</base-button
          >
        </div>
        <!-- Категории сервера -->
        <div class="settings-block categories-block">
          <div class="block__info">
            <span class="block__title"> Категории </span>
            <p class="block__desc">Категории, отражающие особенности сервера</p>
          </div>

          <div class="categories__box" v-if="!categoriesIsLoading">
            <div class="category__box category__main">
              <span class="category__title">Основная:</span>
              <select
                class="select category__select"
                name=""
                id=""
                v-model="serverData.main_category_id"
                @change="filterSecondCategoryOptions"
              >
                <option
                  class="option"
                  v-for="category in filteredMainCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="category__box category__second">
              <span class="category__title">Дополнительная:</span>
              <select
                class="select category__select"
                name=""
                id=""
                v-model="serverData.second_category_id"
                @change="filterMainCategoryOptions"
              >
                <option
                  class="option"
                  v-for="category in filteredSecondCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- Ссылки проекта -->
        <div class="settings-block links-block">
          <div class="block__info">
            <span class="block__title">Ссылки</span>
            <p class="block__desc">
              Ссылки на соц. сети проекта, онлайн карту, донат, а также правила
            </p>
          </div>
          <div class="links__box">
            <span class="link__title">Сайт проекта:</span>
            <base-error v-show="!isUrlValid[0]" :base-error="baseError[0]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Сайт проекта"
              v-model="serverData.site_url"
              :class="checkInputError(0)"
              @blur="checkUrl(serverData.site_url, 0)"
            />

            <span class="link__title">VK:</span>
            <base-error v-show="!isUrlValid[1]" :base-error="baseError[1]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="VK"
              v-model="serverData.vk_url"
              :class="checkInputError(1)"
              @blur="checkUrl(serverData.vk_url, 1)"
            />

            <span class="link__title">Discord:</span>
            <base-error v-show="!isUrlValid[2]" :base-error="baseError[2]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Discord"
              v-model="serverData.discord_url"
              :class="checkInputError(2)"
              @blur="checkUrl(serverData.discord_url, 2)"
            />

            <span class="link__title">Telegram:</span>
            <base-error v-show="!isUrlValid[3]" :base-error="baseError[3]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Telegram"
              v-model="serverData.tg_url"
              :class="checkInputError(3)"
              @blur="checkUrl(serverData.tg_url, 3)"
            />

            <span class="link__title">YouTube:</span>
            <base-error v-show="!isUrlValid[4]" :base-error="baseError[4]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="YouTube"
              v-model="serverData.youtube_url"
              :class="checkInputError(4)"
              @blur="checkUrl(serverData.youtube_url, 4)"
            />

            <span class="link__title">Донат сервера:</span>
            <base-error v-show="!isUrlValid[5]" :base-error="baseError[5]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Донат сервера"
              v-model="serverData.donate_url"
              :class="checkInputError(5)"
              @blur="checkUrl(serverData.donate_url, 5)"
            />

            <span class="link__title">Карта сервера:</span>
            <base-error v-show="!isUrlValid[6]" :base-error="baseError[6]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Карта сервера"
              v-model="serverData.map_url"
              :class="checkInputError(6)"
              @blur="checkUrl(serverData.map_url, 6)"
            />

            <span class="link__title">Правила сервера:</span>
            <base-error v-show="!isUrlValid[7]" :base-error="baseError[7]" />
            <base-input
              class="input link__input"
              type="url"
              placeholder="Правила сервера"
              v-model="serverData.rules_url"
              :class="checkInputError(7)"
              @blur="checkUrl(serverData.rules_url, 7)"
            />
          </div>
        </div>
        <!-- Описание проекта -->
        <div class="settings-block desc-block">
          <div class="block__info info__box">
            <span class="block__title">Описание проекта</span>
            <p class="block__desc">
              Полное описание проекта. Поддерживается разметка Markdown
            </p>
          </div>
          <!-- Сделать распознание разметки Markdown -->
          <textarea
            class="textarea"
            cols="120"
            rows="10"
            placeholder="Описание сервера"
            v-model="serverData.description"
          ></textarea>
        </div>
        <base-error-container
          class="error-box"
          :error-container-msg="errorContainerMsg"
          v-show="errorContainerMsg.visible"
        >
        </base-error-container>
        <base-button class="btn btn_submit" type="submit"
          >Сохранить изменения</base-button
        >
      </form>
    </div>
  </div>
</template>

<script type="text/javascript">
import serversApi from "../services/api/serversApi";
import gameVersionsApi from "../services/api/gameVersionsApi";
import categoriesApi from "../services/api/categoriesApi";
import PictureInput from "vue-picture-input";
import { useToast } from "vue-toastification";

export default {
  name: "ServerCreatePage",
  extends: {},
  props: {},
  data() {
    return {
      serverClaimId: "",
      gameVersions: [],
      serverCategories: [],
      serverData: {
        name: "",
        description: "",
        short_description: "",
        version_from: "",
        version_to: "",
        main_category_id: "",
        second_category_id: "",
        site_url: "",
        vk_url: "",
        discord_url: "",
        tg_url: "",
        youtube_url: "",
        donate_url: "",
        map_url: "",
        rules_url: "",
      },
      serverAddress: "",
      maxLength: 64,
      isOneVersion: true,
      dataIsLoading: true,
      categoriesIsLoading: true,
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      baseError: [
        {
          visible: false,
          text: "",
        },
        { visible: false, text: "" },
        { visible: false, text: "" },
        { visible: false, text: "" },
        { visible: false, text: "" },
        { visible: false, text: "" },
        { visible: false, text: "" },
        { visible: false, text: "" },
      ],
      isUrlValid: [true, true, true, true, true, true, true, true],
    };
  },
  computed: {
    filteredGameVersionsTo() {
      if (this.dataIsLoading) {
        return;
      }

      const versionFrom = this.serverData.version_from;

      if (!versionFrom) {
        return;
      }

      return this.gameVersions.filter(
        (version) =>
          version.position >
          this.gameVersions.find((item) => item.version === versionFrom)
            .position -
            1
      );
    },
    filteredSecondCategories() {
      if (this.serverData.main_category_id) {
        return this.serverCategories.filter(
          (category) => category.id !== this.serverData.main_category_id
        );
      }
      return this.serverCategories;
    },

    filteredMainCategories() {
      if (this.serverData.second_category_id) {
        return this.serverCategories.filter(
          (category) => category.id !== this.serverData.second_category_id
        );
      }
      return this.serverCategories;
    },
  },
  components: {
    PictureInput,
  },
  watch: {},
  methods: {
    async getGameVersions() {
      await gameVersionsApi
        .show()
        .then((response) => {
          this.gameVersions = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении версий игры с сервера", e);
        });
    },

    async getServerCategories() {
      await categoriesApi
        .show()
        .then((response) => {
          this.serverCategories = response.data.result;
          this.categoriesIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении категорий сервера", e);
        });
    },

    async postData() {
      const file = this.$refs.pictureInput.file;
      const formData = new FormData();

      for (let i = 0; i < this.isUrlValid.length; i++) {
        if (this.isUrlValid[i] == false) {
          this.errorContainerMsg.text =
            "Одна или несколько ссылок имеют неверный формат";
          this.errorContainerMsg.visible = true;
          return;
        }
      }

      if (file) {
        formData.append("banner", file);
      }
      if (this.isOneVersion) {
        this.serverData.version_to = this.serverData.version_from;
      }
      formData.append("server_claim_id", this.serverClaimId);
      formData.append("name", this.serverData.name);
      formData.append("description", this.serverData.description);
      formData.append("short_description", this.serverData.short_description);
      formData.append("version_from", this.serverData.version_from);
      formData.append("version_to", this.serverData.version_to);
      formData.append("main_category_id", this.serverData.main_category_id);
      formData.append("second_category_id", this.serverData.second_category_id);
      formData.append("site_url", this.serverData.site_url);
      formData.append("vk_url", this.serverData.vk_url);
      formData.append("discord_url", this.serverData.discord_url);
      formData.append("tg_url", this.serverData.tg_url);
      formData.append("youtube_url", this.serverData.youtube_url);
      formData.append("donate_url", this.serverData.donate_url);
      formData.append("map_url", this.serverData.map_url);
      formData.append("rules_url", this.serverData.rules_url);

      await serversApi
        .create(formData)
        .then(() => {
          this.errorContainerMsg.visible = false;
          useToast().success("Данные успешно сохранены");
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },

    async onChange(image) {
      if (image) {
        console.log("Картинка загружена!");
        this.image = image;
      } else {
        console.log("FileReader API не поддерживается: используйте <form>");
      }
    },

    // checkServerAddress(ip, port) {
    //   if (port != 25565 && port != null) {
    //     this.serverAddress = ip + ":" + port;
    //   } else {
    //     this.serverAddress = ip;
    //   }
    // },

    checkMaxLength() {
      if (this.serverData.short_description.length > this.maxLength) {
        this.serverData.short_description =
          this.serverData.short_description.substr(0, this.maxLength);
      }
    },

    showVersionTo() {
      this.serverData.version_to = this.serverData.version_from;
      this.isOneVersion = false;
    },

    hideVersionTo() {
      this.serverData.version_to = this.serverData.version_from;
      this.isOneVersion = true;
    },
    filterMainCategoryOptions() {
      if (
        this.serverData.main_category_id === this.serverData.second_category_id
      ) {
        this.serverData.main_category_id = null;
      }
    },
    filterSecondCategoryOptions() {
      if (
        this.serverData.main_category_id === this.serverData.second_category_id
      ) {
        this.serverData.second_category_id = null;
      }
    },
    checkUrl(link, inputNumber) {
      const urlPattern =
        /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\\/?%&=]*)?$/;
      this.isUrlValid[inputNumber] = urlPattern.test(link);

      if (link && this.isUrlValid[inputNumber]) {
        if (link.startsWith("https://")) {
          this.isUrlValid[inputNumber] = true;
        } else {
          this.isUrlValid[inputNumber] = false;
          this.baseError[inputNumber].text =
            "Пожалуйста, используйте безопасный протокол (https)";
        }
      } else if (!link) {
        this.isUrlValid[inputNumber] = true;
      } else {
        this.isUrlValid[inputNumber] = false;
        this.baseError[inputNumber].text = "Некорректный URL";
      }
    },
    checkInputError(inputNumber) {
      return {
        "input-error": !this.isUrlValid[inputNumber],
      };
    },
  },
  created() {
    this.serverClaimId = this.$route.query.server_claim_id;
  },
  beforeCreate() {},
  mounted() {
    this.getGameVersions();
    this.getServerCategories();
  },
};
</script>

<style scoped>
.settings-top {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}
.heading {
  font-size: 20px;
  color: var(--color-dark);
}
.btn {
  height: 40px;
}
.settings-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.settings-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.input {
  width: 468px;
  height: 40px;
}
.block__info {
  width: 468px;
}
.block__title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--color-secondary);
}
.block__desc {
  font-size: 14px;
  line-height: 1.2;
}
.short_description__textarea {
  width: 468px;
}
.banner {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 468px;
}
.version__box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 468px;
}
.select {
  width: 100px;
  height: 40px;
}
.option {
  color: var(--color-dark);
}
.version__btn {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-primary);
  background-color: transparent;
}
.version_to {
  margin: 0 10px;
}
.version_to--mobile {
  display: none;
}
.version_to__box {
  display: flex;
  align-items: center;
  height: 40px;
}
.version__btn_mobile {
  display: none;
}
.categories__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 468px;
}
.category__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.category__main {
  margin-bottom: 15px;
}
.category__title {
  margin-bottom: 5px;
}
.category__select {
  width: 100%;
}
.links-block {
  align-items: flex-start;
}
.links__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 468px;
}
.link__title {
  margin-bottom: 5px;
}
.link__input {
  margin-bottom: 15px;
}
.info__box {
  margin-right: 36px;
  width: 25%;
}
.textarea {
  width: 75%;
}
.error-box {
  align-self: center;
  width: 520px;
  word-break: break-all;
}
.btn_submit {
  width: 250px;
  align-self: center;
}
.input-error {
  outline: 2px solid var(--color-error);
}
@media screen and (max-width: 1023px) {
  .settings-block {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .block__info {
    margin-bottom: 15px;
  }
  .info__box {
    margin-right: 0;
    width: 468px;
  }
  .version__box {
    justify-content: space-between;
  }
  .version_to--mobile {
    display: block;
  }
  .version_to {
    display: none;
  }
  .categories__box {
    flex-direction: row;
    justify-content: space-between;
  }
  .category__main {
    margin-bottom: 0;
  }
  .category__main,
  .category__second {
    display: flex;
    flex-direction: column;
  }
  .category__select {
    width: 220px;
  }
  .category__title {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .links-block {
    align-items: center;
  }
}
@media screen and (max-width: 559px) {
  .block__info {
    width: 290px;
  }
  .input,
  .short_description__textarea,
  .textarea {
    width: 290px;
  }
  .version__box {
    width: 290px;
  }
  .version__btn_desktop {
    display: none;
  }
  .version__btn_mobile {
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
  .categories__box {
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
  }
  .category__main {
    margin-bottom: 15px;
  }
  .category__box {
    width: 290px;
  }
  .category__select {
    width: 100%;
  }
  .btn_submit {
    width: 290px;
  }
}
</style>
