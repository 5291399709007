import Api from "../apiService";

class serverClaimsApi {
  // show(id) {
  //   return Api.getRequest(`server_claims/${id}`, {}, Api.authHeaders());
  // } TODO: необходимо реализовать подгрузку ip:port на страницу создания сервера

  createClaim(data) {
    return Api.postRequest(`server_claims`, data, Api.authHeaders());
  }

  create(claimId, data) {
    return Api.postRequest(
      `server_claims/${claimId}/confirm`,
      data,
      Api.authHeaders()
    );
  }
}

export default new serverClaimsApi();
