<template>
  <div class="wrapper">
    <!-- Поиск -->
    <section class="search">
      <search-section @search-servers="searchServers" />
    </section>
    <div class="container main-container">
      <!-- Мониторинг серверов -->
      <section class="servers">
        <h1 class="servers__heading">Мониторинг серверов по Minecraft</h1>
        <base-loader v-show="isLoading" />
        <!-- Список серверов -->
        <server-list id="server-list" :servers="servers" v-if="!isLoading" />
        <base-button
          class="next_page__btn"
          @click="nextPage"
          v-show="totalPages > page && !isLoading"
          >Показать ещё</base-button
        >
      </section>
    </div>
  </div>
</template>

<script type="text/javascript">
import ServerList from "@/components/ServerList";
import SearchSection from "@/components/SearchSection";
import serversApi from "@/services/api/serversApi";

export default {
  name: "MainPage",
  extends: {},
  props: {},
  data() {
    return {
      servers: [],
      page: this.$store.state.page,
      totalPages: 0,
      isLoading: true,
      searchText: "",
    };
  },
  computed: {},
  components: {
    ServerList,
    SearchSection,
  },
  watch: {
    page() {
      this.$store.state.page = this.page;
      this.isLoading = true;
      this.getServers(this.page);
    },
  },
  methods: {
    async getServers(pageNumber) {
      const params = {};

      if (this.searchText && this.searchText != "") {
        params.page = pageNumber;
        params.search = this.searchText;
      } else {
        params.page = pageNumber;
      }

      await serversApi
        .index(params)
        .then((response) => {
          this.totalPages = response.data.pagination_meta.pages;
          this.servers = response.data.result;

          this.isLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении запрашиваемых данных с сервера", e);
        });
    },
    searchServers(searchText) {
      this.searchText = searchText;
      this.page = 0;
      this.nextPage();
      this.getServers(this.page);
    },
    nextPage() {
      this.page += 1;
      this.isLoading = true;
      this.$router.push({
        name: "Home",
        query: { page: this.page },
      });
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  beforeCreate() {},
  beforeUpdate() {
    this.page = this.currentPage();
    if (this.page > this.totalPages) {
      this.page = 1;
      this.$router.push({ name: "Home" });
    }
  },
  updated() {},
  mounted() {
    this.getServers(this.page);
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--color-dark);
}
.search {
  background-color: var(--color-light);
}
.servers__heading {
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  color: var(--color-secondary);
}
.next_page__btn {
  margin-top: 15px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .servers__heading {
    font-size: 24px;
  }
  .next_page__btn {
    height: 50px;
  }
}
</style>
