<template>
  <div class="f-carousel" id="myCarousel">
    <button
      class="carousel__nav prev"
      v-show="totalPages > 1 && currentPage != 1"
      data-carousel-prev
    >
      &lt;
    </button>
    <button
      class="carousel__nav next"
      v-show="totalPages > 1 && totalPages != currentPage"
      data-carousel-next
    >
      &gt;
    </button>
    <div class="f-carousel__viewport">
      <div class="f-carousel__track">
        <a
          v-for="(image, index) in images"
          :key="index"
          :href="image"
          data-fancybox="carousel"
          class="f-carousel__slide"
        >
          <img :src="image" alt="Image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// https://fancyapps.com/fancybox/
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// https://fancyapps.com/carousel/
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";

export default {
  name: "base-carousel",
  extends: {},
  props: {
    options: Object,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 1,
      images: [
        "https://files.vgtimes.ru/gallery/main/186/xbox1-mine.jpg",
        "https://files.vgtimes.ru/gallery/main/186/xbox1-landscape-distance.jpg",
        "https://monitoringminecraft.ru/images/y/2/khfs0p90.png",
        "https://demotivation.ru/wp-content/uploads/2020/11/Alex_Pack_XBoxOne_Screenshot_06.0.0.jpg",
        "https://monitoringminecraft.ru/images/d/s/54wg1smi.jpg",
      ],
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {
    const container = document.getElementById("myCarousel");
    const options = {
      infinite: false,
      Dots: false,
      Navigation: false,
      slidesPerPage: 1,
      center: false,
      on: {
        refresh: (carousel) => {
          this.totalPages = carousel.pages.length;
        },
        change: (carousel) => {
          this.currentPage = carousel.page + 1;
        },
      },
    };

    new Carousel(container, options);

    Fancybox.bind("[data-fancybox]", {
      Carousel: {
        infinite: false,
      },
    });
  },
  updated() {},
  unmounted() {
    Fancybox.destroy();
  },
};
</script>

<style scoped>
#myCarousel {
  position: relative;
  --f-carousel-spacing: 8px;
  --f-carousel-slide-width: calc((100% - 16px) / 3);
}

#myCarousel img {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
.carousel__nav {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 25px;
  color: var(--color-dark);
  background-color: white;
  box-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%),
    0 3px 7px -3px rgb(0 0 0 / 30%);
  z-index: 2;
}
.carousel__nav:hover {
  background-color: var(--color-primary);
}
.carousel__nav:focus {
  background-color: var(--color-secondary);
}
.prev {
  top: calc(50% - 18px);
  left: -18px;
}
.next {
  top: calc(50% - 18px);
  left: calc(100% - 18px);
}
@media ((min-width: 915px) and (max-width: 1024px)) {
  #myCarousel {
    --f-carousel-slide-width: calc((100% - 24px) / 4);
  }
}
@media (max-width: 680px) {
  #myCarousel {
    --f-carousel-slide-width: calc((100% - 16px) / 3);
  }
  .carousel__nav {
    width: 30px;
    height: 30px;
    font-size: 23px;
  }
  .prev {
    top: calc(50% - 15px);
    left: -10px;
  }
  .next {
    top: calc(50% - 15px);
    left: calc(100% - 20px);
  }
}
@media (min-width: 440px) and (max-width: 559px) {
  #myCarousel {
    --f-carousel-slide-width: calc(100% / 2);
  }
}
@media (max-width: 439px) {
  #myCarousel {
    --f-carousel-slide-width: 100%;
  }
}
</style>
