<template>
  <div class="error-block">
    <p class="error__title" v-if="Array.isArray(errorContainerMsg.text)">
      Возникли ошибки:
    </p>
    <p class="error__title" v-else>Возникла ошибка:</p>
    <ul class="error__list" v-if="Array.isArray(errorContainerMsg.text)">
      <li class="error__item" v-for="msg in errorContainerMsg.text" :key="msg">
        - {{ msg }}
      </li>
    </ul>
    <p class="error__text" v-else>- {{ errorContainerMsg.text }}</p>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "base-error-container",
  extends: {},
  props: {
    errorContainerMsg: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.error-block {
  margin-bottom: 30px;
  width: 290px;
  padding: 10px;
  background-color: #ec4b4b29;
  border-left: 3px solid var(--color-error);
  color: var(--color-error);
  overflow: auto;
}
.error__title {
  margin-bottom: 10px;
}
.error__item {
  margin-bottom: 5px;
}
</style>
