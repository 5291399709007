import BaseLogo from "@/components/UI/BaseLogo";
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import BaseLoader from "@/components/UI/BaseLoader";
import BaseTooltip from "@/components/UI/BaseTooltip";
import BaseError from "@/components/UI/BaseError";
import BaseErrorContainer from "@/components/UI/BaseErrorContainer";
import BaseRecaptcha from "@/components/UI/BaseRecaptcha";
import BaseChart from "@/components/UI/BaseChart";
import BaseBigToggle from "@/components/UI/BaseBigToggle";
import BaseCarousel from "@/components/UI/BaseCarousel";

export default [
  BaseLogo,
  BaseButton,
  BaseInput,
  BaseLoader,
  BaseTooltip,
  BaseError,
  BaseErrorContainer,
  BaseRecaptcha,
  BaseChart,
  BaseBigToggle,
  BaseCarousel,
];
