<template>
  <span class="error_msg">
    {{ baseError.text }}
  </span>
</template>

<script type="text/javascript">
export default {
  name: "base-error",
  extends: {},
  props: {
    baseError: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.error_msg {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--color-error);
  text-align: center;
  word-break: keep-all;
}
</style>
