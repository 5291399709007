<template>
  <header class="header">
    <div class="container header-container">
      <base-logo class="logo" />
      <div class="header-right">
        <base-button
          class="btn btn_add"
          role="link"
          @click="this.$router.push({ name: 'ServerAdd' })"
        >
          Добавить сервер
        </base-button>
        <base-button
          class="btn btn_login"
          role="link"
          v-if="!isAuth"
          @click="showModal"
        >
          Войти
        </base-button>
        <base-button
          class="btn btn_login"
          role="link"
          v-else
          @click="this.$router.push({ name: 'Account' })"
        >
          Профиль
        </base-button>
      </div>
    </div>
  </header>
</template>

<script type="text/javascript">
export default {
  name: "app-header",
  extends: {},
  props: {},
  data() {
    return {
      isAuth: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    showModal() {
      this.$emit("show-modal");
    },
    authTest() {
      const token = localStorage.getItem("token");
      if (token) {
        this.isAuth = true;
      }
    },
  },
  beforeCreate() {},
  mounted() {
    this.authTest();
  },
};
</script>

<style scoped>
.header {
  background-color: var(--color-dark);
}
.header-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 100px;
}
.logo {
  margin-right: auto;
}
.header-right {
  display: flex;
  align-items: center;
}
.btn_add {
  margin-right: 30px;
}
@media screen and (max-width: 1023px) {
  .btn {
    min-width: 170px;
    width: unset;
  }
}
@media screen and (max-width: 767px) {
  .header-container {
    justify-content: center;
  }
  .logo {
    margin: unset;
    font-size: 32px;
  }
  .header-right {
    display: none;
  }
}
</style>
