import store from "../store";

export function checkTokenValidity(to, from, next) {
  const token = localStorage.getItem("token");
  const exp = localStorage.getItem("exp");
  const expirationTime = new Date(parseInt(exp) * 1000);

  if (token && exp && expirationTime > new Date()) {
    // Токен действителен
    next();
  } else {
    // Запоминаем путь к текущей страницы
    store.state.loginError.returnToPage = to.path;
    // Переходим к странице логина
    next({ name: "Login" });
  }
}
