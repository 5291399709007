<template>
  <section class="registration">
    <div class="container container-registration">
      <div class="center-block">
        <h2 class="heading">Завершение регистрации</h2>
        <form class="registration-form" @submit.prevent>
          <label class="input-desc" for="input1">Email</label>
          <base-input
            class="input email__input"
            id="input1"
            v-model="confirmRegData.email"
            disabled
          />
          <label class="input-desc" for="input2">Имя пользователя</label>
          <base-input
            class="input"
            id="input2"
            placeholder="Имя пользователя"
            v-model="confirmRegData.username"
          />
          <label class="input-desc" for="input3">Пароль</label>
          <base-input
            class="input"
            id="input3"
            placeholder="Пароль"
            type="password"
            autocomplete="new-password"
            v-model="confirmRegData.password"
          />
          <label class="input-desc" for="input4">Подтвердить пароль</label>
          <base-input
            class="input"
            id="input4"
            placeholder="Подтвердить пароль"
            type="password"
            autocomplete="new-password"
            v-model="confirmRegData.password_confirmation"
          />
          <base-error-container
            class="error-box"
            :error-container-msg="errorContainerMsg"
            v-show="errorContainerMsg.visible"
          />
          <base-recaptcha />
          <base-button class="btn" type="submit" @click="sendData"
            >Создать аккаунт</base-button
          >
        </form>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import usersApi from "../services/api/usersApi";

export default {
  name: "ConfirmRegPage",
  extends: {},
  props: {},
  data() {
    return {
      confirmRegData: {
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
        code: "",
        "g-recaptcha-response-data": "",
      },
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async sendData() {
      this.confirmRegData["g-recaptcha-response-data"] =
        this.$store.state.captchaResponse;

      await usersApi
        .create(this.confirmRegData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.errorContainerMsg.visible = false;

            const token = response.data.token;
            const userId = response.data.user_id;
            const exp = response.data.exp;

            localStorage.setItem("userId", userId);
            localStorage.setItem("token", token);
            localStorage.setItem("exp", exp);
            this.$router.push({ name: "Home" }).then(() => {
              location.reload();
            });
          } else {
            this.errorContainerMsg.visible = true;
            if (response.status != 200) {
              this.errorContainerMsg.text = response.data.errors;
            }
          }
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  created() {
    this.confirmRegData.code = this.$route.query.code;
    this.confirmRegData.email = this.$route.query.email;
    this.confirmRegData.username = this.$route.query.username;
  },
  mounted() {},
};
</script>

<style scoped>
.registration {
  background-color: var(--color-light);
}
.heading {
  margin-bottom: 30px;
  color: var(--color-dark);
  text-align: center;
}
.center-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-desc {
  margin-bottom: 5px;
  font-size: 16px;
}
.input {
  margin-bottom: 15px;
  width: 290px;
  height: 40px;
}
.email__input {
  color: var(--color-dark);
  background-color: #cdcdcd;
}
#input4 {
  margin-bottom: 30px;
}
.btn {
  width: 290px;
  height: 40px;
  font-size: 16px;
}
</style>
