<template>
  <div class="wrapper">
    <div class="container server-container">
      <base-loader v-show="dataIsLoading" />
      <div class="server-header" v-if="!dataIsLoading">
        <div class="header-top">
          <div class="left-box">
            <div class="position-box">
              <span class="server__position">{{ server.position }}</span>
            </div>
            <div class="title-box">
              <strong class="server__name">{{ server.name }}</strong>
              <!-- Слоган -->
              <span class="server__short_desc">{{
                server.short_description
              }}</span>
              <div class="server-category">
                <div
                  class="category-box main-box"
                  :style="{
                    backgroundColor: getCategoryColor(server.main_category_id),
                  }"
                  v-if="server.main_category_id"
                >
                  <svg
                    class="category__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="15"
                    height="15"
                  >
                    <path
                      d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z"
                    />
                  </svg>
                  <span>{{ getCategoryName(server.main_category_id) }}</span>
                </div>
                <div
                  class="category-box second-box"
                  :style="{
                    backgroundColor: getCategoryColor(
                      server.second_category_id
                    ),
                  }"
                  v-if="server.second_category_id"
                >
                  <svg
                    class="category__icon"
                    id="Layer_1"
                    height="15"
                    viewBox="0 0 24 24"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                  >
                    <path
                      d="m23.414.587a2.02 2.02 0 0 0 -1.941-.513l-3.715 1.218-12.24 12.24a14.58 14.58 0 0 0 -4.376-1.522l-.286 1.979a12.638 12.638 0 0 1 5.354 2.387l-3.71 3.71-1.024-1.024-1.414 1.414 3.5 3.5 1.414-1.414-1.062-1.062 3.71-3.71a12.615 12.615 0 0 1 2.386 5.354l1.98-.286a14.515 14.515 0 0 0 -1.522-4.376l12.24-12.24 1.245-3.81a1.994 1.994 0 0 0 -.539-1.845zm-2.458 4.579-11.593 11.594a11.287 11.287 0 0 0 -.989-1.134 11.36 11.36 0 0 0 -1.133-.988l11.594-11.594 3.157-1.037z"
                    />
                  </svg>
                  <span class="category__name category__second">{{
                    getCategoryName(server.second_category_id)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="server__banner" v-if="server.banner_url == ''" />
          <img
            class="server__banner"
            loading="lazy"
            :src="server.banner_url"
            :alt="altText(server.name)"
            v-else
          />
        </div>
        <div class="header-bottom">
          <div class="btns-box">
            <div class="play-block">
              <base-button class="btn__play">Играть на сервере</base-button>
              <button class="btn__copy_ip" @click="copyToClipboard">
                <base-tooltip
                  :tooltip="tooltip"
                  v-show="tooltip.visible"
                ></base-tooltip>
                <span>{{ buttonText }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="16"
                  height="17"
                >
                  <path
                    d="m19,0h-6c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5Zm3,11c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h6c1.654,0,3,1.346,3,3v6Zm-6,8c0,2.757-2.243,5-5,5h-6c-2.757,0-5-2.243-5-5v-6c0-2.757,2.243-5,5-5,.553,0,1,.448,1,1s-.447,1-1,1c-1.654,0-3,1.346-3,3v6c0,1.654,1.346,3,3,3h6c1.654,0,3-1.346,3-3,0-.552.447-1,1-1s1,.448,1,1Z"
                  />
                </svg>
              </button>
            </div>
            <div class="vote-box">
              <span class="vote__desc"
                >Проголосуй за проект и получи награду</span
              >
              <base-button class="btn__vote" @click="openVote"
                >Проголосовать</base-button
              >
            </div>
            <base-button class="btn__vote btn__vote_mobile" @click="openVote"
              >Проголосовать</base-button
            >
          </div>
        </div>
        <div class="server-center">
          <div class="center-content">
            <div class="server-info">
              <!-- Версия -->
              <div class="status-box">
                <span class="box__title"> Версия: </span>
                <span
                  class="server__version"
                  v-if="
                    server.version_to != null &&
                    server.version_from != server.version_to
                  "
                  >{{ server.version_from }} - {{ server.version_to }}</span
                >
                <span class="server__version" v-else>{{
                  server.version_from
                }}</span>
              </div>
              <!-- Онлайн -->
              <div class="status-box">
                <span class="box__title"> Текущий онлайн: </span>
                <span
                  class="server__online"
                  v-if="server.status != 'offline' && server.status != null"
                  >{{ server.current_online }} / {{ server.max_online }}</span
                >
                <span class="server__online" v-else>Офлайн</span>
              </div>
              <!-- Рейтинг -->
              <div class="status-box">
                <span class="box__title"> Рейтинг: </span>
                <span class="server__rating">{{
                  server ? server.rating : ""
                }}</span>
              </div>
            </div>
            <div
              class="server-block mobile__links"
              v-if="
                server.site_url ||
                server.vk_url ||
                server.discord_url ||
                server.tg_url ||
                server.youtube_url ||
                server.donate_url ||
                server.map_url ||
                server.rules_url
              "
            >
              <h5 class="desc__title">Полезные ссылки</h5>
              <ul class="link-list">
                <li class="link__item" v-if="server.site_url">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="link__icon"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="m23.414,16.09s-8.734-3.97-8.765-3.98c-.728-.248-1.518-.065-2.062.479s-.727,1.334-.442,2.151l3.944,8.675c.159.351.506.579.892.586h.019c.378,0,.725-.214.895-.553l1.851-3.702,3.702-1.851c.345-.173.56-.527.553-.913-.007-.385-.235-.732-.586-.892Zm-4.861,2.016c-.193.097-.351.254-.447.447l-1.064,2.128-3.044-6.676,6.681,3.038-2.125,1.062Zm-5.641,3.854c-.172.015-.345.026-.52.033-.668-.661-2.41-2.518-3.624-4.992h.732c.552,0,1-.447,1-1s-.448-1-1-1h-1.53c-.291-.952-.47-1.96-.47-3s.18-2.047.472-3h8.057c.118.387.218.783.295,1.187.104.543.629.901,1.169.795.542-.103.898-.627.795-1.169-.052-.275-.114-.546-.183-.813h3.393c.013,0,.027,0,.04,0,.299.948.46,1.956.46,3.001,0,.307-.014.61-.041.91-.049.55.356,1.036.906,1.086.552.054,1.037-.356,1.086-.906.032-.359.049-.723.049-1.09C24,5.383,18.617,0,12,0S0,5.383,0,12s5.383,12,11.999,12h0c.367,0,.73-.017,1.088-.049.55-.049.957-.534.908-1.085-.049-.549-.533-.955-1.084-.907Zm-7.02-6.959h-3.392c-.013,0-.026,0-.04,0-.299-.948-.46-1.956-.46-3.001s.161-2.053.46-3.001c.013,0,.026,0,.04,0h3.392c-.245.955-.392,1.959-.392,3s.146,2.045.392,3Zm2.881-8c1.021-2.077,2.415-3.724,3.229-4.588.815.863,2.209,2.507,3.23,4.588h-6.459Zm11.885,0h-3.228c-.791-1.886-1.888-3.488-2.832-4.657,2.579.695,4.749,2.397,6.06,4.657ZM9.4,2.344c-.944,1.169-2.042,2.771-2.832,4.656h-3.226c1.31-2.26,3.479-3.962,6.058-4.656Zm-6.058,14.656h3.226c.79,1.886,1.888,3.487,2.832,4.656-2.578-.695-4.747-2.397-6.058-4.656Z"
                    />
                  </svg>
                  <a class="link" :href="server.site_url">Сайт сервера</a>
                </li>
                <li class="link__item" v-if="server.vk_url">
                  <svg
                    class="link__icon"
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="m23.4493 5.94799c.1668-.54645 0-.94799-.794-.94799h-2.6256c-.6676 0-.9754.34687-1.1423.72936 0 0-1.3353 3.19671-3.2268 5.27314-.6119.6011-.8901.7924-1.2239.7924-.1669 0-.4173-.1913-.4173-.7377v-5.10921c0-.65574-.1849-.94799-.7413-.94799h-4.12597c-.41719 0-.6681.30434-.6681.59278 0 .62163.94571.76499 1.04319 2.51363v3.79779c0 .8326-.15309.9836-.4869.9836-.89009 0-3.05525-3.21098-4.33939-6.88519-.25166-.71414-.50407-1.00261-1.1751-1.00261h-2.625612c-.750174 0-.900218.34687-.900218.72936 0 .68308.890141 4.07103 4.14464 8.55184 2.16965 3.06 5.22654 4.7188 8.00816 4.7188 1.669 0 1.8755-.3684 1.8755-1.003v-2.3128c0-.7368.1581-.8839.6866-.8839.3894 0 1.057.1913 2.6147 1.6667 1.7803 1.7486 2.0738 2.533 3.0751 2.533h2.6257c.7501 0 1.1252-.3684.9088-1.0955-.2368-.7246-1.0867-1.7759-2.2145-3.0222-.612-.7104-1.53-1.4754-1.8082-1.858-.3894-.4917-.2781-.7103 0-1.1474 0 0 3.199-4.42623 3.5328-5.92891z"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <a class="link" :href="server.vk_url">Группа VK</a>
                </li>
                <li class="link__item" v-if="server.discord_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.074,4.361a14.243,14.243,0,0,0-3.61-1.134,10.61,10.61,0,0,0-.463.96,13.219,13.219,0,0,0-4,0,10.138,10.138,0,0,0-.468-.96A14.206,14.206,0,0,0,3.919,4.364,15.146,15.146,0,0,0,1.324,14.5a14.435,14.435,0,0,0,4.428,2.269A10.982,10.982,0,0,0,6.7,15.21a9.294,9.294,0,0,1-1.494-.727c.125-.093.248-.19.366-.289a10.212,10.212,0,0,0,8.854,0c.119.1.242.2.366.289a9.274,9.274,0,0,1-1.5.728,10.8,10.8,0,0,0,.948,1.562,14.419,14.419,0,0,0,4.431-2.27A15.128,15.128,0,0,0,16.074,4.361Zm-8.981,8.1a1.7,1.7,0,0,1-1.573-1.79A1.689,1.689,0,0,1,7.093,8.881a1.679,1.679,0,0,1,1.573,1.791A1.687,1.687,0,0,1,7.093,12.462Zm5.814,0a1.7,1.7,0,0,1-1.573-1.79,1.689,1.689,0,0,1,1.573-1.791,1.679,1.679,0,0,1,1.573,1.791A1.688,1.688,0,0,1,12.907,12.462Z"
                    />
                  </svg>
                  <a class="link" :href="server.discord_url">Discord сервера</a>
                </li>
                <li class="link__item" v-if="server.tg_url">
                  <svg
                    class="link__icon"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m29.919 6.163-4.225 19.925c-.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-.344.344-.631.631-1.294.631l.463-6.556 11.931-10.781c.519-.462-.113-.719-.806-.256l-14.75 9.288-6.35-1.988c-1.381-.431-1.406-1.381.288-2.044l24.837-9.569c1.15-.431 2.156.256 1.781 2.013z"
                    />
                  </svg>
                  <a class="link" :href="server.tg_url">Telegram сервера</a>
                </li>
                <li class="link__item" v-if="server.youtube_url">
                  <svg
                    class="link__icon"
                    height="24"
                    width="24"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 461.001 461.001"
                    xml:space="preserve"
                  >
                    <path
                      d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                    ></path>
                  </svg>
                  <a class="link" :href="server.youtube_url">YouTube сервера</a>
                </li>
                <li class="link__item" v-if="server.donate_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M18,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1ZM7,5c.552,0,1-.447,1-1,0-1.103,.897-2,2-2s2,.897,2,2c0,.553,.448,1,1,1s1-.447,1-1c0-2.206-1.794-4-4-4S6,1.794,6,4c0,.553,.448,1,1,1ZM24,13v2c0,1.106-.748,1.779-1.568,1.954-.661,1.654-1.872,3.051-3.432,3.954v.092c0,1.654-1.346,3-3,3-1.304,0-2.416-.836-2.829-2h-2.343c-.413,1.164-1.525,2-2.829,2-1.654,0-3-1.346-3-3v-.079c-2.635-1.519-4.182-4.377-3.983-7.451,.063-.978,.31-1.907,.705-2.756-1.017-.481-1.723-1.516-1.723-2.714,0-1.654,1.346-3,3-3,.552,0,1,.447,1,1s-.448,1-1,1-1,.448-1,1c0,.491,.356,.9,.823,.984,1.521-1.823,3.853-2.984,6.443-2.984h5.363c.733-1.478,2.102-2.512,3.927-2.946,.591-.139,1.203-.003,1.681,.374,.485,.384,.764,.958,.764,1.576v3.715c.608,.691,1.094,1.481,1.432,2.327,.821,.175,1.568,.848,1.568,1.954Zm-2,0h-.294c-.442,0-.832-.291-.958-.715-.274-.92-.78-1.776-1.463-2.477-.183-.187-.285-.438-.285-.698V5.004c-.975,.232-2.265,.83-2.764,2.335-.141,.423-.553,.714-.99,.684-.068-.003-5.98-.022-5.98-.022-3.303,0-6.05,2.459-6.253,5.599-.16,2.469,1.181,4.752,3.417,5.815,.349,.166,.57,.518,.57,.903v.683c0,.552,.449,1,1,1s1-.448,1-1,.448-1,1-1h4c.552,0,1,.447,1,1s.449,1,1,1,1-.448,1-1v-.694c0-.385,.221-.736,.569-.902,1.542-.736,2.7-2.081,3.179-3.688,.126-.424,.516-.715,.958-.715h.294v-2Z"
                    />
                  </svg>
                  <a class="link" :href="server.donate_url">Донат</a>
                </li>
                <li class="link__item" v-if="server.map_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M21.867,1.612,17.515.155A2.956,2.956,0,0,0,15.724.12L8.538,2.092,4.155.233A3,3,0,0,0,0,3V21.754l7.982,2.281,8.021-2,8,1.948V4.483A3,3,0,0,0,21.867,1.612ZM15,2.384V20.219l-6,1.5V3.972ZM2,3A1,1,0,0,1,3.387,2.08L7,3.581V21.674L2,20.246Zm20,18.43-5-1.218V2.092l4.275,1.43A1,1,0,0,1,22,4.483Z"
                    />
                  </svg>
                  <a class="link" :href="server.map_url">Карта мира</a>
                </li>
                <li class="link__item" v-if="server.rules_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M20,0H5c-1.654,0-3,1.346-3,3V21c0,1.654,1.346,3,3,3H22V2c0-1.103-.897-2-2-2Zm0,18H8V2h4V11l2.5-2.5,2.5,2.5V2h3V18ZM5,2h1V18h-1c-.351,0-.687,.061-1,.172V3c0-.551,.449-1,1-1Zm0,20c-.551,0-1-.449-1-1s.449-1,1-1h15v2H5Z"
                    />
                  </svg>
                  <a class="link" :href="server.rules_url">Правила сервера</a>
                </li>
              </ul>
            </div>
            <div class="server-block server-images">
              <span class="desc__title">Скриншоты</span>
              <base-carousel />
            </div>
            <div class="server-block server-desc">
              <span class="desc__title">Описание сервера</span>
              <div class="description" v-html="parsedMD"></div>
            </div>
            <div class="sidebar-block sidebar-block__charts mobile-charts">
              <h5 class="desc__title">Статистика сервера</h5>
              <div class="chart" v-if="numberOfChart == 1">
                <base-chart
                  :chart-data="chartData1"
                  :chart-options="chartOptions"
                />
              </div>
              <div class="chart" v-else>
                <base-chart
                  :chart-data="chartData2"
                  :chart-options="chartOptions"
                />
              </div>
              <base-big-toggle
                class="big_toggle"
                :label-left="'Онлайн'"
                :label-right="'Голоса'"
                @change-content="showChart"
              />
            </div>
            <div id="vk_comments"></div>
          </div>
          <!-- Sidebar -->
          <aside class="sidebar">
            <!-- Ссылки проекта -->
            <div
              class="sidebar-block sidebar-block__links"
              v-if="
                server.site_url ||
                server.vk_url ||
                server.discord_url ||
                server.tg_url ||
                server.youtube_url ||
                server.donate_url ||
                server.map_url ||
                server.rules_url
              "
            >
              <h5 class="desc__title">Полезные ссылки</h5>
              <ul class="link-list">
                <li class="link__item" v-if="server.site_url">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="link__icon"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="m23.414,16.09s-8.734-3.97-8.765-3.98c-.728-.248-1.518-.065-2.062.479s-.727,1.334-.442,2.151l3.944,8.675c.159.351.506.579.892.586h.019c.378,0,.725-.214.895-.553l1.851-3.702,3.702-1.851c.345-.173.56-.527.553-.913-.007-.385-.235-.732-.586-.892Zm-4.861,2.016c-.193.097-.351.254-.447.447l-1.064,2.128-3.044-6.676,6.681,3.038-2.125,1.062Zm-5.641,3.854c-.172.015-.345.026-.52.033-.668-.661-2.41-2.518-3.624-4.992h.732c.552,0,1-.447,1-1s-.448-1-1-1h-1.53c-.291-.952-.47-1.96-.47-3s.18-2.047.472-3h8.057c.118.387.218.783.295,1.187.104.543.629.901,1.169.795.542-.103.898-.627.795-1.169-.052-.275-.114-.546-.183-.813h3.393c.013,0,.027,0,.04,0,.299.948.46,1.956.46,3.001,0,.307-.014.61-.041.91-.049.55.356,1.036.906,1.086.552.054,1.037-.356,1.086-.906.032-.359.049-.723.049-1.09C24,5.383,18.617,0,12,0S0,5.383,0,12s5.383,12,11.999,12h0c.367,0,.73-.017,1.088-.049.55-.049.957-.534.908-1.085-.049-.549-.533-.955-1.084-.907Zm-7.02-6.959h-3.392c-.013,0-.026,0-.04,0-.299-.948-.46-1.956-.46-3.001s.161-2.053.46-3.001c.013,0,.026,0,.04,0h3.392c-.245.955-.392,1.959-.392,3s.146,2.045.392,3Zm2.881-8c1.021-2.077,2.415-3.724,3.229-4.588.815.863,2.209,2.507,3.23,4.588h-6.459Zm11.885,0h-3.228c-.791-1.886-1.888-3.488-2.832-4.657,2.579.695,4.749,2.397,6.06,4.657ZM9.4,2.344c-.944,1.169-2.042,2.771-2.832,4.656h-3.226c1.31-2.26,3.479-3.962,6.058-4.656Zm-6.058,14.656h3.226c.79,1.886,1.888,3.487,2.832,4.656-2.578-.695-4.747-2.397-6.058-4.656Z"
                    />
                  </svg>
                  <a class="link" :href="server.site_url">Сайт сервера</a>
                </li>
                <li class="link__item" v-if="server.vk_url">
                  <svg
                    class="link__icon"
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="m23.4493 5.94799c.1668-.54645 0-.94799-.794-.94799h-2.6256c-.6676 0-.9754.34687-1.1423.72936 0 0-1.3353 3.19671-3.2268 5.27314-.6119.6011-.8901.7924-1.2239.7924-.1669 0-.4173-.1913-.4173-.7377v-5.10921c0-.65574-.1849-.94799-.7413-.94799h-4.12597c-.41719 0-.6681.30434-.6681.59278 0 .62163.94571.76499 1.04319 2.51363v3.79779c0 .8326-.15309.9836-.4869.9836-.89009 0-3.05525-3.21098-4.33939-6.88519-.25166-.71414-.50407-1.00261-1.1751-1.00261h-2.625612c-.750174 0-.900218.34687-.900218.72936 0 .68308.890141 4.07103 4.14464 8.55184 2.16965 3.06 5.22654 4.7188 8.00816 4.7188 1.669 0 1.8755-.3684 1.8755-1.003v-2.3128c0-.7368.1581-.8839.6866-.8839.3894 0 1.057.1913 2.6147 1.6667 1.7803 1.7486 2.0738 2.533 3.0751 2.533h2.6257c.7501 0 1.1252-.3684.9088-1.0955-.2368-.7246-1.0867-1.7759-2.2145-3.0222-.612-.7104-1.53-1.4754-1.8082-1.858-.3894-.4917-.2781-.7103 0-1.1474 0 0 3.199-4.42623 3.5328-5.92891z"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <a class="link" :href="server.vk_url">Группа VK</a>
                </li>
                <li class="link__item" v-if="server.discord_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.074,4.361a14.243,14.243,0,0,0-3.61-1.134,10.61,10.61,0,0,0-.463.96,13.219,13.219,0,0,0-4,0,10.138,10.138,0,0,0-.468-.96A14.206,14.206,0,0,0,3.919,4.364,15.146,15.146,0,0,0,1.324,14.5a14.435,14.435,0,0,0,4.428,2.269A10.982,10.982,0,0,0,6.7,15.21a9.294,9.294,0,0,1-1.494-.727c.125-.093.248-.19.366-.289a10.212,10.212,0,0,0,8.854,0c.119.1.242.2.366.289a9.274,9.274,0,0,1-1.5.728,10.8,10.8,0,0,0,.948,1.562,14.419,14.419,0,0,0,4.431-2.27A15.128,15.128,0,0,0,16.074,4.361Zm-8.981,8.1a1.7,1.7,0,0,1-1.573-1.79A1.689,1.689,0,0,1,7.093,8.881a1.679,1.679,0,0,1,1.573,1.791A1.687,1.687,0,0,1,7.093,12.462Zm5.814,0a1.7,1.7,0,0,1-1.573-1.79,1.689,1.689,0,0,1,1.573-1.791,1.679,1.679,0,0,1,1.573,1.791A1.688,1.688,0,0,1,12.907,12.462Z"
                    />
                  </svg>
                  <a class="link" :href="server.discord_url">Discord сервера</a>
                </li>
                <li class="link__item" v-if="server.tg_url">
                  <svg
                    class="link__icon"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m29.919 6.163-4.225 19.925c-.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-.344.344-.631.631-1.294.631l.463-6.556 11.931-10.781c.519-.462-.113-.719-.806-.256l-14.75 9.288-6.35-1.988c-1.381-.431-1.406-1.381.288-2.044l24.837-9.569c1.15-.431 2.156.256 1.781 2.013z"
                    />
                  </svg>
                  <a class="link" :href="server.tg_url">Telegram сервера</a>
                </li>
                <li class="link__item" v-if="server.youtube_url">
                  <svg
                    class="link__icon"
                    height="24"
                    width="24"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 461.001 461.001"
                    xml:space="preserve"
                  >
                    <path
                      d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                    ></path>
                  </svg>
                  <a class="link" :href="server.site_url">YouTube сервера</a>
                </li>
                <li class="link__item" v-if="server.donate_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M18,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1ZM7,5c.552,0,1-.447,1-1,0-1.103,.897-2,2-2s2,.897,2,2c0,.553,.448,1,1,1s1-.447,1-1c0-2.206-1.794-4-4-4S6,1.794,6,4c0,.553,.448,1,1,1ZM24,13v2c0,1.106-.748,1.779-1.568,1.954-.661,1.654-1.872,3.051-3.432,3.954v.092c0,1.654-1.346,3-3,3-1.304,0-2.416-.836-2.829-2h-2.343c-.413,1.164-1.525,2-2.829,2-1.654,0-3-1.346-3-3v-.079c-2.635-1.519-4.182-4.377-3.983-7.451,.063-.978,.31-1.907,.705-2.756-1.017-.481-1.723-1.516-1.723-2.714,0-1.654,1.346-3,3-3,.552,0,1,.447,1,1s-.448,1-1,1-1,.448-1,1c0,.491,.356,.9,.823,.984,1.521-1.823,3.853-2.984,6.443-2.984h5.363c.733-1.478,2.102-2.512,3.927-2.946,.591-.139,1.203-.003,1.681,.374,.485,.384,.764,.958,.764,1.576v3.715c.608,.691,1.094,1.481,1.432,2.327,.821,.175,1.568,.848,1.568,1.954Zm-2,0h-.294c-.442,0-.832-.291-.958-.715-.274-.92-.78-1.776-1.463-2.477-.183-.187-.285-.438-.285-.698V5.004c-.975,.232-2.265,.83-2.764,2.335-.141,.423-.553,.714-.99,.684-.068-.003-5.98-.022-5.98-.022-3.303,0-6.05,2.459-6.253,5.599-.16,2.469,1.181,4.752,3.417,5.815,.349,.166,.57,.518,.57,.903v.683c0,.552,.449,1,1,1s1-.448,1-1,.448-1,1-1h4c.552,0,1,.447,1,1s.449,1,1,1,1-.448,1-1v-.694c0-.385,.221-.736,.569-.902,1.542-.736,2.7-2.081,3.179-3.688,.126-.424,.516-.715,.958-.715h.294v-2Z"
                    />
                  </svg>
                  <a class="link" :href="server.donate_url">Донат</a>
                </li>
                <li class="link__item" v-if="server.map_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M21.867,1.612,17.515.155A2.956,2.956,0,0,0,15.724.12L8.538,2.092,4.155.233A3,3,0,0,0,0,3V21.754l7.982,2.281,8.021-2,8,1.948V4.483A3,3,0,0,0,21.867,1.612ZM15,2.384V20.219l-6,1.5V3.972ZM2,3A1,1,0,0,1,3.387,2.08L7,3.581V21.674L2,20.246Zm20,18.43-5-1.218V2.092l4.275,1.43A1,1,0,0,1,22,4.483Z"
                    />
                  </svg>
                  <a class="link" :href="server.map_url">Карта мира</a>
                </li>
                <li class="link__item" v-if="server.rules_url">
                  <svg
                    class="link__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M20,0H5c-1.654,0-3,1.346-3,3V21c0,1.654,1.346,3,3,3H22V2c0-1.103-.897-2-2-2Zm0,18H8V2h4V11l2.5-2.5,2.5,2.5V2h3V18ZM5,2h1V18h-1c-.351,0-.687,.061-1,.172V3c0-.551,.449-1,1-1Zm0,20c-.551,0-1-.449-1-1s.449-1,1-1h15v2H5Z"
                    />
                  </svg>
                  <a class="link" :href="server.rules_url">Правила сервера</a>
                </li>
              </ul>
            </div>
            <div class="sidebar-block sidebar-block__charts">
              <h5 class="desc__title">Статистика сервера</h5>
              <base-loader v-show="chartDataIsLoading" />
              <div
                class="chart"
                v-if="numberOfChart == 1 && chartDataIsLoading == false"
              >
                <base-chart
                  :chart-data="chartData1"
                  :chart-options="chartOptions"
                />
              </div>
              <div
                class="chart"
                v-else-if="numberOfChart != 1 && chartDataIsLoading == false"
              >
                <base-chart
                  :chart-data="chartData2"
                  :chart-options="chartOptions"
                />
              </div>
              <base-big-toggle
                class="big_toggle"
                :label-left="'Онлайн'"
                :label-right="'Голоса'"
                @change-content="showChart"
              />
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import serversApi from "../services/api/serversApi";
import categoriesApi from "../services/api/categoriesApi";
import serverStatisticsApi from "../services/api/serverStatisticsApi";
import MarkdownIt from "markdown-it";
import VK from "vk-openapi";

export default {
  name: "ServerPage",
  extends: {},
  props: {},
  data() {
    return {
      server: {},
      serverCategories: [],
      serverId: 0,
      description: "",
      dataIsLoading: true,
      chartDataIsLoading: true,
      tooltip: { visible: false, text: "IP скопирован" },
      buttonText: "",
      numberOfChart: 1,
      chartData1: {
        labels: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        datasets: [
          {
            label: "Текущая неделя",
            borderWidth: 2,
            backgroundColor: "#7ae5af",
            borderColor: "#7ae5af",
            data: [],
          },
          {
            label: "Прошлая неделя",
            borderWidth: 2,
            backgroundColor: "#f3a738",
            borderColor: "#f3a738",
            data: [],
          },
        ],
      },
      chartData2: {
        labels: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        datasets: [
          {
            label: "Текущая неделя",
            borderWidth: 2,
            backgroundColor: "#7ae5af",
            borderColor: "#7ae5af",
            data: [],
          },
          {
            label: "Прошлая неделя",
            borderWidth: 2,
            backgroundColor: "#f3a738",
            borderColor: "#f3a738",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 3,
            },
          },
        },
      },
    };
  },
  computed: {
    parsedMD() {
      const markdown = new MarkdownIt();
      return markdown.render(this.description);
    },
  },
  components: {},
  watch: {},
  methods: {
    async getServer() {
      await serversApi
        .show(this.serverId)
        .then((response) => {
          this.server = response.data.result;
          this.getElements();
          this.checkBtnText();
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении запрашиваемых данных с сервера", e);
        });
    },

    async getServerCategories() {
      await categoriesApi
        .show()
        .then((response) => {
          this.serverCategories = response.data.result;
        })
        .catch((e) => {
          console.log("Ошибка при получении категорий сервера", e);
        });
    },

    async getServerStatistics() {
      await serverStatisticsApi
        .show(this.serverId)
        .then((response) => {
          // Подгружаем график онлайна
          this.chartData1.datasets[0].data =
            response.data.result.online.current;
          this.chartData1.datasets[1].data = response.data.result.online.prev;

          // Подгружаем график голосов
          this.chartData2.datasets[0].data = response.data.result.votes.current;
          this.chartData2.datasets[1].data = response.data.result.votes.prev;

          this.chartDataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении данных статистики сервера", e);
        });
    },

    getDates() {
      const today = new Date();
      const dates = [];

      dates.push(`${today.getDate()}.${today.getMonth() + 1}`);
      for (let i = 1; i <= 6; i++) {
        dates.unshift(`${today.getDate() - i}.${today.getMonth() + 1}`);
      }
      return dates;
    },

    getElements() {
      this.description = this.server.description;
      if (this.description == null) {
        this.description = "";
      }
    },

    async copyToClipboard() {
      const textToCopy = this.buttonText;

      try {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        this.hideTooltip();
      } catch (e) {
        console.error("Ошибка при копировании текста в буфер обмена:", e);
      }
    },

    hideTooltip() {
      this.tooltip.visible = true;
      setTimeout(() => {
        this.tooltip.visible = false;
      }, 5000);
    },

    openVote() {
      this.$router.push({
        name: "Vote",
        params: { id: this.serverId },
      });
    },

    checkBtnText() {
      if (this.server.port != 25565 && this.server.port != null) {
        this.buttonText = `${this.server.ip}:${this.server.port}`;
      } else {
        this.buttonText = this.server.ip;
      }
    },

    getCategoryName(categoryId) {
      const category = this.serverCategories.find((c) => c.id === categoryId);
      return category ? category.name : "";
    },

    getCategoryColor(categoryId) {
      const category = this.serverCategories.find((c) => c.id === categoryId);
      return category ? category.color : "";
    },

    altText(serverName) {
      const text = "Баннер сервера " + serverName;
      return text;
    },

    showChart(chartNumber) {
      this.numberOfChart = chartNumber;
    },
  },
  created() {
    this.serverId = this.$route.params.id;
    this.getServer();
    this.getServerCategories();
    this.getServerStatistics();
  },
  beforeCreate() {},
  mounted() {
    VK.init({ apiId: 51810010, onlyWidgets: true });
    VK.Widgets.Comments("vk_comments", { limit: 10, attach: "*", height: 500 });
  },
};
</script>

<style>
.description p {
  margin: 10px 0;
}
.description h1,
.description h2,
.description h3 {
  font-size: 20px;
}
.description pre {
  max-width: 500px;
}
.description {
  overflow: hidden;
}
</style>

<style scoped>
.wrapper {
  background-color: #f0f0f0;
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 30px 30px 30px 0;
  background-color: var(--color-light);
  border-radius: 10px;
}
.left-box {
  display: flex;
  align-items: center;
}
.position-box {
  display: flex;
  justify-content: center;
  width: 90px;
}
.server__position {
  font-size: 26px;
}
.title-box {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.server__name {
  font-size: 28px;
  margin-bottom: 10px;
}
.server__short_desc {
  max-width: 290px;
  margin-bottom: 10px;
  word-break: break-all;
}
.server-category {
  display: flex;
}
.category-box {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 7px;
  background-color: #a9cce3;
}
.main-box {
  margin-right: 15px;
}
.category__icon {
  margin-right: 4px;
}
.category__name {
  font-size: 15px;
}
.server__banner {
  margin-left: 30px;
  width: 468px;
  height: auto;
  aspect-ratio: 13 / 1.6667;
  overflow: hidden;
  border-radius: 10px;
  background-color: var(--color-secondary);
}
.header-bottom {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.btns-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
}
.play-block {
  display: flex;
}
.btn__play {
  width: 220px;
  height: 50px;
  margin-right: 32px;
}
.btn__copy_ip {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  padding-right: 12px;
  width: 230px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--color-secondary-light);
}
.btn__copy_ip span {
  margin-right: 5px;
  width: 195px;
  font-size: 14px;
  color: var(--color-dark) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vote-box {
  padding: 10px 15px;
  background-color: var(--color-light);
  border-radius: 10px;
}
.vote__desc {
  margin-right: 15px;
  font-size: 14px;
}
.btn__vote {
  width: 160px;
}
.btn__vote_mobile {
  display: none;
}
.server-center {
  display: flex;
  justify-content: space-between;
}
.center-content {
  width: 68%;
}
.server-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 30px;
  background-color: var(--color-light);
  border-radius: 10px;
}
.status-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box__title {
  margin-bottom: 10px;
}
.server-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 30px;
  background-color: var(--color-light);
  border-radius: 10px;
}
.mobile__links {
  display: none;
}
.desc__title {
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-secondary);
}
.description {
  line-height: 1.5;
  font-family: "Inter";
}
.sidebar {
  width: 30%;
}
.sidebar-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 30px;
  background-color: var(--color-light);
  border-radius: 10px;
}
.mobile-charts {
  display: none;
}
.link-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.link__item {
  position: relative;
  display: flex;
  align-items: center;
}
.link__item:not(:last-child) {
  margin-bottom: 15px;
}
.link__item:hover .link {
  color: #0bb05e;
}
.link__item:hover .link__icon path {
  transition: all 0.45s linear;
  fill: #0bb05e;
}
.link__icon {
  margin-right: 10px;
}
.link__icon path {
  fill: #3c3b3b;
}
.link {
  color: var(--color-dark);
}
.link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.chart {
  margin-bottom: 30px;
  width: 100%;
}
.big_toggle {
  height: 20px;
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  .server__banner {
    width: 360px;
  }
  .btns-box {
    align-items: center;
  }
  .vote-box {
    display: none;
  }
  .btn__vote_mobile {
    display: block;
    width: 220px;
    height: 50px;
  }
  .server-center {
    flex-direction: column;
  }
  .center-content {
    margin-bottom: 30px;
    width: 100%;
  }
  .server-block {
    margin-bottom: 30px;
  }
  .sidebar {
    display: none;
  }
  .sidebar-block__links {
    display: none;
  }
  .mobile-charts {
    display: flex;
  }
  .mobile__links {
    display: flex;
  }
}
@media screen and (min-width: 560px) and (max-width: 1024px) {
  .link-list {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .link__item {
    display: flex;
    width: 33%;
  }
  .link__item:not(:last-child) {
    margin-bottom: 0;
  }
  .link__item:not(:nth-child(-n + 3)) {
    margin-top: 15px;
  }
  .link__item:nth-child(3n + 2) {
    justify-content: center;
  }
  .link__item:nth-child(3n) {
    justify-content: flex-end;
  }
  .link {
    width: 145px;
  }
}
@media screen and (max-width: 767px) {
  .header-top {
    flex-direction: column;
    align-items: start;
  }
  .left-box {
    margin-bottom: 15px;
    width: 100%;
  }
  .server__name {
    font-size: 20px;
  }
  .server__short_desc {
    font-size: 14px;
  }
  .server__banner {
    margin-left: 90px;
  }
  .play-block {
    margin-bottom: 15px;
  }
  .btns-box {
    flex-direction: column;
    align-items: center;
  }
  .play-block {
    justify-content: center;
  }
  .vote-box {
    display: block;
  }
  .btn__vote_mobile {
    display: none;
  }
  .link {
    width: 70px;
  }
}
@media screen and (min-width: 440px) and (max-width: 559px) {
  .link {
    width: 150px;
  }
}
@media screen and (max-width: 559px) {
  .header-top {
    align-items: center;
    padding: 10px;
  }
  .left-box {
    justify-content: center;
    width: 100%;
  }
  .title-box {
    align-items: center;
    width: 100%;
  }
  .position-box {
    display: none;
  }
  .server__name {
    text-align: center;
  }
  .server__banner {
    margin-left: 0;
    width: 100%;
  }
  .play-block {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .btn__play {
    margin-right: 0;
    margin-bottom: 15px;
    width: 290px;
    height: 40px;
  }
  .btn__copy_ip {
    width: 290px;
    height: 40px;
  }
  .btn__copy_ip span {
    width: 255px;
  }
  .desc__title {
    font-size: 18px;
  }
  .mobile-charts {
    padding: 15px;
  }
  .vote-box {
    display: flex;
    flex-direction: column;
  }
  .vote__desc {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .btn__vote {
    width: 270px;
  }
  .server-info {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .link-list {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .link__item {
    width: 50%;
  }
  .link__item:not(:last-child) {
    margin-bottom: 0;
  }
  .link__item:nth-child(2n) {
    justify-content: flex-end;
  }
  .link__item:not(:nth-child(-n + 2)) {
    margin-top: 15px;
  }
  .status-box {
    flex-direction: row;
    margin-bottom: 10px;
  }
  .status-box:last-child {
    margin-bottom: 0;
  }
  .box__title {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .server-block {
    justify-content: center;
    padding: 15px;
  }
  .desc__title {
    margin-bottom: 15px;
  }
}
</style>
