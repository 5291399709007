<template>
  <div class="container search-container">
    <form class="search-form" @submit.prevent>
      <base-input
        class="input input-search"
        placeholder="Название или IP"
        v-model="searchText"
      />
      <base-button class="btn search-btn" @click="checkSearchText"
        >Найти сервер</base-button
      >
    </form>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "search-section",
  extends: {},
  props: {},
  data() {
    return {
      searchText: "",
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    checkSearchText() {
      if (this.searchText != "") {
        this.$emit("search-servers", this.searchText);
      } else {
        console.log("Сервер по вашему запросу не найден");
      }
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.search-container {
  display: flex;
  justify-content: center;
}
.search-form {
  display: flex;
}
.input {
  height: 40px;
  border: 1px solid var(--color-input-border);
}
.input-search {
  padding: 0 65px;
  width: 605px;
  margin-right: 30px;
  background: no-repeat url("../assets/svg/search.svg");
  background-position: 25px;
}
.search-btn {
  padding: 0 10px;
  width: 190px;
  height: 40px;
  white-space: nowrap;
}
@media screen and (max-width: 1023px) {
  .search-form {
    width: 60%;
  }
  .input-search {
    margin-right: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .search-form {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .input-search {
    margin-right: unset;
    margin-bottom: 15px;
  }
}
</style>
