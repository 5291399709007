<template>
  <div class="container account_recovery-content">
    <div class="content-center" v-if="!isLoginExists">
      <h2 class="content__heading">Восстановление пароля</h2>
      <form class="content__form" @submit.prevent="postLogin">
        <label for="input1" class="input__desc">Логин или email</label>
        <base-input
          id="input1"
          class="input form__input"
          type="text"
          placeholder="Логин или email"
          v-model="login"
        />
        <base-recaptcha />
        <base-error-container
          class="error-box"
          :error-container-msg="errorContainerMsg"
          v-show="errorContainerMsg.visible"
        />
        <base-button class="btn submit__btn" type="submit"
          >Восстановить аккаунт</base-button
        >
      </form>
    </div>
    <div class="content-center" v-else>
      <p class="success__desc">
        Отправили письмо с ссылкой на восстановление пароля вам на почту
      </p>
    </div>
  </div>
</template>

<script>
import usersApi from "../services/api/usersApi";

export default {
  name: "AccountRecoveryPage",
  extends: {},
  props: {},
  data() {
    return {
      login: "",
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      isLoginExists: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async postLogin() {
      await usersApi
        .sendRecoveryToken({
          login: this.login,
          "g-recaptcha-response-data": this.$store.state.captchaResponse,
        })
        .then(() => {
          this.errorContainerMsg.visible = false;
          this.isLoginExists = true;
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
  text-align: center;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc {
  margin-bottom: 10px;
}
.input {
  margin-bottom: 20px;
  width: 290px;
  height: 40px;
}
.g-recaptcha {
  margin-bottom: 20px;
  transform: scale(0.95);
}
.btn {
  width: 290px;
}
.success__desc {
  margin-bottom: 10px;
  width: 290px;
  text-align: center;
}
@media screen and (max-width: 559px) {
  .btn {
    height: 50px;
  }
}
</style>
