import Api from "../apiService";

class serverVotesApi {
  index(serverId, pageNumber) {
    return Api.getRequest(
      `servers/${serverId}/server_votes`,
      {
        page: pageNumber,
      },
      Api.authHeaders()
    );
  }

  create(serverId, data) {
    return Api.postRequest(
      `servers/${serverId}/server_votes`,
      data,
      Api.authHeaders()
    );
  }
}

export default new serverVotesApi();
