<template>
  <div class="wrapper">
    <div class="container edit-container">
      <aside class="edit-sidebar">
        <nav class="sidebar__menu">
          <ul class="menu__list">
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(1) }"
                @click="changeContent(1)"
                >Редактировать</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(2) }"
                @click="changeContent(2)"
                >Рейтинг сервера</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(3) }"
                @click="changeContent(3)"
                >RCON</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(4) }"
                @click="changeContent(4)"
                >Вебхуки для голосов</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(5) }"
                @click="changeContent(5)"
                >Команды</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(6) }"
                @click="changeContent(6)"
                >Дискорд оповещения</base-button
              >
            </li>
            <li class="menu__item">
              <base-button
                class="btn menu__btn"
                :class="{ active__btn: isActiveBtn(7) }"
                @click="changeContent(7)"
                >Голоса</base-button
              >
            </li>
          </ul>
        </nav>
      </aside>
      <div class="account-content">
        <server-edit :server-id="serverId" v-if="currentContent == 1" />
        <server-rating v-else-if="currentContent == 2" />
        <vote-reward
          v-else-if="currentContent == 3"
          :server-id="serverSettings.server_id"
        />
        <vote-webhooks
          :server-settings="serverSettings"
          v-else-if="currentContent == 4"
        />
        <vote-commands
          :server-id="serverSettings.server_id"
          v-else-if="currentContent == 5"
        />
        <discord-notifications
          :server-settings="serverSettings"
          v-else-if="currentContent == 6"
        />
        <vote-list
          :server-id="serverSettings.server_id"
          v-else-if="currentContent == 7"
        />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import ServerEdit from "@/components/ServerEdit";
import ServerRating from "@/components/ServerRating";
import DiscordNotifications from "@/components/DiscordNotifications";
import VoteReward from "@/components/VoteReward";
import VoteWebhooks from "@/components/VoteWebhooks";
import VoteCommands from "@/components/VoteCommands";
import VoteList from "@/components/VoteList";

import serverSettingsApi from "../services/api/serverSettingsApi";

export default {
  name: "ServerSettingsPage",
  extends: {},
  props: {},
  data() {
    return {
      currentContent: 1,
      serverId: "",
      dataIsLoading: true,
      serverSettings: {},
    };
  },
  computed: {},
  components: {
    ServerEdit,
    ServerRating,
    DiscordNotifications,
    VoteReward,
    VoteWebhooks,
    VoteCommands,
    VoteList,
  },
  watch: {},
  methods: {
    changeContent(content) {
      this.currentContent = content;
    },

    isActiveBtn(content) {
      return this.currentContent == content;
    },

    async getServerSettings() {
      await serverSettingsApi
        .show(this.serverId)
        .then((response) => {
          this.serverSettings = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении настроек сервера", e);
          if (e.response.status == 404) {
            this.$router.push({ name: "Account" });
          }
        });
    },
  },
  created() {
    this.serverId = this.$route.params.id;
  },
  beforeCreate() {},
  mounted() {
    this.getServerSettings();
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #f0f0f0;
}
.edit-container {
  display: flex;
  min-height: calc(100vh - 350px);
}
.edit-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
  padding: 30px;
  min-height: 450px;
  max-height: calc(100vh - 450px);
  background-color: #fff;
  border-radius: 10px;
}
.menu__item {
  margin-bottom: 10px;
}
.menu__btn {
  background-color: transparent;
}
.account-content {
  padding: 30px;
  width: 100%;
  background-color: var(--color-light);
  border-radius: 10px;
}
.active__btn {
  outline: 3px solid var(--color-secondary);
}
</style>
