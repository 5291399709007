<template>
  <div class="registration-container" :class="{ bglight: loginPageStyle }">
    <h3 class="registration-heading" :class="{ colordark: loginPageStyle }">
      Регистрация
    </h3>
    <!-- Форма логина -->
    <form class="registration-form" @submit.prevent>
      <base-input
        class="input email-input"
        type="email"
        placeholder="Email"
        v-model="email"
        required
      />
      <base-recaptcha />
      <base-error-container
        class="error-box"
        :error-container-msg="errorContainerMsg"
        v-show="errorContainerMsg.visible"
      />
      <base-button class="btn next-btn" type="submit" @click="postRegData"
        >Зарегистрироваться</base-button
      >
    </form>
    <!-- Войти с помощью соц сетей -->
    <div class="login-alternative">
      <span class="alternative-desc" :class="{ colordark: loginPageStyle }"
        >или войти с помощью</span
      >
      <ul class="social-list">
        <li class="social-item discord">
          <a class="social-link" href="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="6" fill="#5865F2" />
              <path
                d="M29.0245 11.675C27.3414 10.8925 25.5419 10.3238 23.6605 10C23.4294 10.4151 23.1595 10.9735 22.9734 11.4176C20.9734 11.1187 18.9918 11.1187 17.0286 11.4176C16.8425 10.9735 16.5664 10.4151 16.3333 10C14.4499 10.3238 12.6482 10.8946 10.9652 11.6791C7.57053 16.7767 6.65029 21.7476 7.11041 26.648C9.36194 28.3188 11.5439 29.3338 13.6891 29.9979C14.2188 29.2736 14.6912 28.5035 15.0981 27.692C14.3231 27.3994 13.5807 27.0382 12.8793 26.6189C13.0654 26.482 13.2474 26.3387 13.4233 26.1914C17.7014 28.1798 22.3496 28.1798 26.5766 26.1914C26.7545 26.3387 26.9365 26.482 27.1206 26.6189C26.4171 27.0403 25.6727 27.4014 24.8977 27.6941C25.3046 28.5035 25.775 29.2757 26.3067 30C28.4539 29.3358 30.638 28.3209 32.8895 26.648C33.4294 20.9672 31.9672 16.0419 29.0245 11.675ZM15.6809 23.6343C14.3967 23.6343 13.3435 22.4429 13.3435 20.9921C13.3435 19.5413 14.3742 18.3479 15.6809 18.3479C16.9877 18.3479 18.0409 19.5392 18.0184 20.9921C18.0204 22.4429 16.9877 23.6343 15.6809 23.6343ZM24.319 23.6343C23.0347 23.6343 21.9815 22.4429 21.9815 20.9921C21.9815 19.5413 23.0122 18.3479 24.319 18.3479C25.6257 18.3479 26.6789 19.5392 26.6564 20.9921C26.6564 22.4429 25.6257 23.6343 24.319 23.6343Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
        <li class="social-item vk">
          <a class="social-link" href="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40 34.4444C40 37.5133 37.5133 40 34.4444 40H5.55556C2.48778 40 0 37.5133 0 34.4444V5.55556C0 2.48667 2.48778 0 5.55556 0H34.4444C37.5133 0 40 2.48667 40 5.55556V34.4444Z"
                fill="#1976D2"
              />
              <path
                d="M33.2637 13.3793C33.3148 13.2115 33.3392 13.056 33.3326 12.9171C33.3159 12.5149 33.0392 12.2227 32.3881 12.2227H29.4792C28.7448 12.2227 28.4059 12.6671 28.2081 13.1127C28.2081 13.1127 26.3948 16.8449 24.3048 19.306C23.627 20.0182 23.2825 20.0004 22.9159 20.0004C22.7192 20.0004 22.2225 19.7627 22.2225 19.1104V13.3493C22.2225 12.5782 22.0303 12.2227 21.4092 12.2227H16.2437C15.7914 12.2227 15.5559 12.5782 15.5559 12.9349C15.5559 13.676 16.5537 13.8538 16.667 15.9304V19.956C16.667 20.9338 16.497 21.1115 16.1303 21.1115C15.1414 21.1115 13.1948 17.7782 11.8914 13.4093C11.6092 12.5493 11.327 12.2227 10.5925 12.2227H7.65588C6.8081 12.2227 6.66699 12.6382 6.66699 13.0827C6.66699 13.8838 7.33366 18.2149 10.9725 23.1949C13.6114 26.806 17.0881 28.8893 20.1659 28.8893C22.0303 28.8893 22.2214 28.4149 22.2214 27.6738V24.3715C22.2225 23.4815 22.4259 23.3338 23.0192 23.3338C23.4425 23.3338 24.3059 23.6115 25.9725 25.556C27.8948 27.7982 28.2437 28.8893 29.3459 28.8893H32.2548C32.9303 28.8893 33.3181 28.606 33.3337 28.056C33.337 27.916 33.317 27.7593 33.2714 27.5849C33.0559 26.9449 32.067 25.3804 30.8337 23.8893C30.1503 23.0638 29.4759 22.246 29.1659 21.8015C28.9581 21.5115 28.8792 21.3071 28.8892 21.1115C28.8992 20.906 29.0059 20.7104 29.1659 20.4371C29.137 20.4371 32.897 15.1582 33.2637 13.3793Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <!-- Переключение с формы логина на форму регистрации -->
    <div class="form-switcher">
      <span class="switcher__desc" :class="{ colordark: loginPageStyle }"
        >Уже есть аккаунт?</span
      >
      <base-button
        class="switch__btn"
        :class="{ colorsecondary: loginPageStyle }"
        @click="showLoginForm"
        >Войти</base-button
      >
    </div>
  </div>
</template>

<script type="text/javascript">
import userClaimsApi from "../services/api/userClaimsApi";

export default {
  name: "registration-form",
  extends: {},
  props: {
    loginPageStyle: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      email: "",
      errorContainerMsg: { visible: false, text: "" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    showLoginForm() {
      this.$emit("show-login-form");
    },

    closeAndGo() {
      this.$emit("close-modal");
      this.$router.push({
        name: "RegEmail",
        query: { email: this.email },
      });
    },

    async postRegData() {
      await userClaimsApi
        .create({
          email: this.email,
          "g-recaptcha-response-data": this.$store.state.captchaResponse,
        })
        .then(() => {
          this.errorContainerMsg.visible = false;
          this.closeAndGo();
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 50px 30px 50px;
  background: var(--color-dark);
  border-radius: 30px;
}
.registration-heading {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  color: var(--color-light);
}
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.input {
  margin-bottom: 20px;
  width: 290px;
  height: 40px;
}
.btn {
  width: 290px;
  height: 40px;
}
.next-btn {
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}
.login-alternative {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.alternative-desc {
  margin-bottom: 20px;
  font-size: 15px;
  color: var(--color-light);
}
.social-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.social-item,
.social-link {
  width: 40px;
  height: 40px;
}
.social-item:not(:last-child) {
  margin-right: 16px;
}
.form-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 339.68px;
}
.switcher__desc {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-light);
}
.switch__btn {
  width: unset;
  height: unset;
  background-color: transparent;
  font-weight: bold;
  color: var(--color-primary);
}
/* Стили на странице login */
.bglight {
  background-color: var(--color-light);
}
.colordark {
  color: var(--color-dark);
}
.colorsecondary {
  color: var(--color-secondary);
}

@media screen and (max-width: 767px) {
  .registration-container {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background-color: transparent;
  }
  .registration-heading {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .form-switcher {
    width: 290px;
  }
  .switcher__desc {
    font-size: 13px;
  }
  .switch__btn {
    font-size: 13px;
  }
}
</style>
