<template>
  <div class="app" v-cloak>
    <div class="wrapper">
      <burger-button class="burger__btn" @click="openMenuPanel" />
      <!-- Меню на мобильных устройствах -->
      <mobile-menu :is-menu-open="isMenuOpen" @close-menu="openMenuPanel">
        <login-form v-if="loginVisible === true" @show-reg-form="showRegForm" />
        <registration-form v-else @show-login-form="showLoginForm" />
      </mobile-menu>
      <!-- Модальное окно -->
      <modal-window
        :is-modal-open="isModalOpen"
        @close="closeModal"
        @click="closeModal"
      >
        <login-form
          v-if="loginVisible === true"
          @show-reg-form="showRegForm"
          @close-modal="closeModal"
          @click.stop
        />
        <registration-form
          v-else
          @show-login-form="showLoginForm"
          @close-modal="closeModal"
          @click.stop
        />
      </modal-window>
      <!-- Header -->
      <app-header class="header" @show-modal="openModal" />
      <!-- Main -->
      <main class="main">
        <router-view></router-view>
      </main>
      <!-- Footer -->
      <app-footer class="footer" @show-modal="openModal" />
    </div>
  </div>
</template>

<script type="text/javascript">
import AppHeader from "@/components/AppHeader";
import ModalWindow from "@/components/ModalWindow";
import AppFooter from "@/components/AppFooter";
import LoginForm from "@/components/LoginForm";
import RegistrationForm from "@/components/RegistrationForm";
import BurgerButton from "@/components/BurgerButton";
import MobileMenu from "@/components/MobileMenu";

export default {
  extends: {},
  props: {},
  data() {
    return {
      loginVisible: true,
      isMenuOpen: false,
      isModalOpen: false,
    };
  },
  computed: {},
  components: {
    AppHeader,
    AppFooter,
    ModalWindow,
    LoginForm,
    RegistrationForm,
    BurgerButton,
    MobileMenu,
  },
  watch: {},
  methods: {
    openModal() {
      this.isModalOpen = true;
      document.querySelector("body").classList.add("scroll-lock");
    },
    closeModal() {
      this.isModalOpen = false;
      document.querySelector("body").classList.remove("scroll-lock");
      this.loginVisible = true;
    },
    showRegForm() {
      this.loginVisible = false;
    },
    showLoginForm() {
      this.loginVisible = true;
    },
    openMenuPanel() {
      if (!this.isMenuOpen) {
        this.isMenuOpen = true;
        this.$store.commit("mobileMenuToggle");
        document.querySelector("body").classList.add("scroll-lock");
      } else {
        this.isMenuOpen = false;
        this.$store.commit("mobileMenuToggle");
        this.$store.state.isMobileMenuBtnEnterPressed = false;
        this.loginVisible = true;
        document.querySelector("body").classList.remove("scroll-lock");
      }
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.scroll-lock {
  overflow: hidden;
}
</style>
<style scoped>
[v-cloak] {
  display: none;
}
.app {
  background-color: var(--color-light);
}
.wrapper {
  position: relative;
}
.burger__btn {
  display: none;
}
.main {
  min-height: calc(100vh - 348px);
  border-top: 2px solid var(--color-light);
  border-bottom: 2px solid var(--color-light);
}
@media screen and (max-width: 767px) {
  .burger__btn {
    display: block;
    position: absolute;
    left: 5%;
    top: 30px;
  }
  .main {
    min-height: calc(100vh - 306px);
  }
}
</style>
