import { createStore } from "vuex";

export default createStore({
  state: {
    page: 1,
    searchText: "",
    limitOnPage: 50,
    loginError: { text: "", returnToPage: "" },
    isMobileMenuOpen: false,
    isMobileMenuBtnEnterPressed: false,
    captchaResponse: "",
  },
  getters: {},
  mutations: {
    mobileMenuToggle(state) {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
  },
  actions: {},
  modules: {},
});
