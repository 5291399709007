import Api from "../apiService";

class serverSettingsApi {
  show(serverId) {
    return Api.getRequest(
      `servers/${serverId}/server_setting`,
      {},
      Api.authHeaders()
    );
  }

  webhookUpdate(serverId, data) {
    return Api.patchRequest(
      `servers/${serverId}/server_setting/webhook_update`,
      data,
      Api.authHeaders()
    );
  }

  discordWebhookUpdate(serverId, data) {
    return Api.patchRequest(
      `servers/${serverId}/server_setting/discord_webhook_update`,
      data,
      Api.authHeaders()
    );
  }
}

export default new serverSettingsApi();
