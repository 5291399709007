<template>
  <a class="logo" @click="goToHomePage">MineHub</a>
</template>

<script type="text/javascript">
export default {
  name: "base-logo",
  extends: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    goToHomePage() {
      this.$router.push({ name: "Home" }).then(() => {
        location.reload();
      });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.logo {
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  color: var(--color-secondary);
}
.logo:focus {
  outline: 3px solid var(--color-primary);
}
</style>
