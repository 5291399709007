import Api from "../apiService";

class serversApi {
  index(params) {
    return Api.getRequest(`servers`, params, {});
  }

  show(serverId) {
    return Api.getRequest(`servers/${serverId}`, {}, {});
  }

  create(data) {
    return Api.postRequest(`servers`, data, Api.authHeadersServerEdit());
  }

  update(serverId, data) {
    return Api.patchRequest(
      `servers/${serverId}`,
      data,
      Api.authHeadersServerEdit()
    );
  }

  serverDataShow(serverId) {
    return Api.getRequest(`servers/${serverId}`, {}, Api.authHeaders());
  }
}

export default new serversApi();
