<template>
  <div class="vote_reward-content">
    <!-- <h3 class="content__heading">Система поощрений за голосование</h3> -->
    <h3 class="content__heading">RCON</h3>
    <base-loader v-if="dataIsLoading" />
    <form class="content__form" @submit.prevent="patchServerRcon" v-else>
      <label for="input1" class="input__desc">IP сервера</label>
      <base-input
        id="input1"
        class="input form__input"
        type="text"
        placeholder="IP сервера"
        v-model="serverRcon.ip"
      ></base-input>
      <label for="input2" class="input__desc">Port сервера</label>
      <base-input
        id="input2"
        class="input form__input"
        type="text"
        placeholder="Port сервера"
        v-model="serverRcon.port"
      ></base-input>
      <label for="input3" class="input__desc">Пароль</label>
      <base-input
        id="input3"
        class="input form__input"
        type="password"
        autocomplete="new-password"
        placeholder="Пароль"
        v-model="password"
      ></base-input>
      <span class="textarea__desc">Vote reward command</span>
      <textarea
        class="textarea form__textarea"
        cols="50"
        rows="3"
        placeholder="Vote reward command"
        v-model="serverRcon.vote_reward_command"
      ></textarea>
      <base-error-container
        class="error-box"
        :error-container-msg="errorContainerMsg"
        v-show="errorContainerMsg.visible"
      >
      </base-error-container>
      <base-button class="btn submit__btn" type="submit">Сохранить</base-button>
    </form>
  </div>
</template>

<script type="text/javascript">
import serverRconsApi from "../services/api/serverRconsApi";
import { useToast } from "vue-toastification";

export default {
  name: "vote-reward",
  extends: {},
  props: {
    serverId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataIsLoading: true,
      password: "",
      serverRcon: {},
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async getServerRcon() {
      await serverRconsApi
        .index(this.serverId)
        .then((response) => {
          this.serverRcon = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении данных с сервера", e);
        });
    },

    async patchServerRcon() {
      this.dataIsLoading = true;
      this.serverRcon.serverId = this.serverId;
      this.serverRcon.password = this.password;

      await serverRconsApi
        .update(this.serverId, this.serverRcon)
        .then(() => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = false;
          useToast().success("Данные успешно сохранены");
        })
        .catch((e) => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {
    this.getServerRcon();
  },
};
</script>

<style scoped>
.vote_reward-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc,
.textarea__desc {
  margin-bottom: 10px;
}
.input,
.textarea {
  width: 520px;
}
.input {
  margin-bottom: 20px;
  height: 40px;
}
.textarea {
  margin-bottom: 20px;
}
</style>
