<template>
  <div class="server">
    <!-- Позиция в рейтинге, название, описание, баннер проекта -->
    <div class="server-left">
      <div class="server-left__box">
        <span class="server__position">{{ truePosition() }}</span>
      </div>
      <div class="server__main" @click="openServer">
        <strong class="server__name">{{ server.name }}</strong>
        <span class="server__desc">{{ server.short_description }}</span>
        <div class="server__banner" v-if="server.banner_url == ''" />
        <img
          class="server__banner"
          loading="lazy"
          :src="server.banner_url"
          :alt="altText(server.name)"
          v-else
        />
      </div>
    </div>

    <!-- Версия, онлайн, рейтинг проекта -->
    <div class="server-center">
      <!-- Версия -->
      <div class="server-center__box">
        <svg
          class="server-center__icon"
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          width="22"
          height="22"
        >
          <path
            d="m16.298,8.288l1.404,1.425-5.793,5.707c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701Zm7.702,3.712c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
          />
        </svg>

        <span
          class="server__version"
          v-if="
            server.version_to != null &&
            server.version_from != server.version_to
          "
          >{{ server.version_from }} - {{ server.version_to }}</span
        >
        <span class="server__version" v-else>{{ server.version_from }}</span>
      </div>
      <!-- Онлайн -->
      <div class="server-center__box">
        <svg
          class="server-center__icon"
          id="Layer_1"
          height="22"
          viewBox="0 0 24 24"
          width="22"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
        >
          <path
            d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 14a5.006 5.006 0 0 0 -5-5h-5a5.006 5.006 0 0 0 -5 5v4h2v-4a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v4h2zm2.5-11a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm6.5 14a5.006 5.006 0 0 0 -5-5h-4v2h4a3 3 0 0 1 3 3v4h2z"
          />
        </svg>
        <span
          class="server__online"
          v-if="server.status != 'offline' && server.status != null"
          >{{ server.current_online }} / {{ server.max_online }}</span
        >
        <span class="server__online" v-else>Офлайн</span>
      </div>
      <!-- Рейтинг -->
      <div class="server-center__box">
        <svg
          class="server-center__icon"
          xmlns="http://www.w3.org/2000/svg"
          id="Outline"
          viewBox="0 0 24 24"
          width="22"
          height="22"
        >
          <path
            d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z"
          />
        </svg>

        <span class="server__rating">{{ server.rating }}</span>
      </div>
    </div>
    <!-- ---------------------------------------------- -->
    <div class="server-center__box2">
      <svg
        class="server-center__icon"
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width="22"
        height="22"
      >
        <path
          d="m16.298,8.288l1.404,1.425-5.793,5.707c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701Zm7.702,3.712c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
        />
      </svg>

      <span
        class="server__version"
        v-if="
          server.version_to != null && server.version_from != server.version_to
        "
        >{{ server.version_from }} - {{ server.version_to }}</span
      >
      <span class="server__version" v-else>{{ server.version_from }}</span>
    </div>
    <!-- Онлайн -->
    <div class="server-center__box2">
      <svg
        class="server-center__icon"
        id="Layer_1"
        height="22"
        viewBox="0 0 24 24"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
      >
        <path
          d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 14a5.006 5.006 0 0 0 -5-5h-5a5.006 5.006 0 0 0 -5 5v4h2v-4a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v4h2zm2.5-11a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm6.5 14a5.006 5.006 0 0 0 -5-5h-4v2h4a3 3 0 0 1 3 3v4h2z"
        />
      </svg>
      <span
        class="server__online"
        v-if="server.status != 'offline' && server.status != null"
        >{{ server.current_online }} / {{ server.max_online }}</span
      >
      <span class="server__online" v-else>Офлайн</span>
    </div>
    <!-- Рейтинг -->
    <div class="server-center__box2">
      <svg
        class="server-center__icon"
        xmlns="http://www.w3.org/2000/svg"
        id="Outline"
        viewBox="0 0 24 24"
        width="22"
        height="22"
      >
        <path
          d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z"
        />
      </svg>

      <span class="server__rating">{{ server.rating }}</span>
    </div>
    <!-- ----------------------------------------------- -->

    <!-- IP сервера и кнопка играть -->
    <div class="server-right">
      <button class="btn_copy_ip" @click="copyToClipboard">
        <base-tooltip
          :tooltip="tooltip"
          v-show="tooltip.visible"
        ></base-tooltip>
        <span>{{ buttonText }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          width="14"
          height="15"
        >
          <path
            d="m19,0h-6c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5Zm3,11c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h6c1.654,0,3,1.346,3,3v6Zm-6,8c0,2.757-2.243,5-5,5h-6c-2.757,0-5-2.243-5-5v-6c0-2.757,2.243-5,5-5,.553,0,1,.448,1,1s-.447,1-1,1c-1.654,0-3,1.346-3,3v6c0,1.654,1.346,3,3,3h6c1.654,0,3-1.346,3-3,0-.552.447-1,1-1s1,.448,1,1Z"
          />
        </svg>
      </button>

      <base-button class="btn_play" role="link" @click="openServer">
        Играть
      </base-button>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "server-item",
  extends: {},
  props: {
    server: {
      type: Object,
      required: true,
    },
    position: Number,
  },
  data() {
    return {
      page: this.$store.state.page,
      limitOnPage: this.$store.state.limitOnPage,
      buttonText: "",
      tooltip: { visible: false, text: "IP скопирован" },
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    truePosition() {
      return this.limitOnPage * (this.page - 1) + this.position + 1;
    },
    openServer() {
      this.$router.push({
        name: "Servers",
        params: { id: this.server.id },
      });
    },
    checkBtnText() {
      if (this.server.port != 25565 && this.server.port != null) {
        this.buttonText = `${this.server.ip}:${this.server.port}`;
      } else {
        this.buttonText = this.server.ip;
      }
    },
    async copyToClipboard() {
      const textToCopy = this.buttonText;

      try {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        this.hideTooltip();
      } catch (e) {
        console.error("Ошибка при копировании текста в буфер обмена:", e);
      }
    },
    hideTooltip() {
      this.tooltip.visible = true;
      setTimeout(() => {
        this.tooltip.visible = false;
      }, 5000);
    },
    altText(serverName) {
      const text = "Баннер сервера " + serverName;
      return text;
    },
  },
  beforeCreate() {},
  mounted() {
    this.checkBtnText();
  },
  beforeUnmount() {},
};
</script>

<style scoped>
.server {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  max-width: 1260px;
  min-height: 160px;
  background-color: var(--color-light);
  border-radius: 10px;
  font-style: normal;
  color: var(--color-dark);
}
.server-left {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.server-left__box {
  display: flex;
  width: 70px;
}
.server__position {
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
}
.server__main {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.server__name {
  margin-bottom: 10px;
  max-width: 374px;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}
.server__desc {
  display: flex;
  align-items: center;
  max-width: 374px;
  min-height: 30px;
  margin-bottom: 10px;
  font-size: 15px;
  word-break: break-all;
}
.server__banner {
  width: 374px;
  height: auto;
  aspect-ratio: 13 / 1.6667;
  overflow: hidden;
  background-color: orange;
  border-radius: 10px;
}
.server-center {
  display: none;
}
.server-center__box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  min-width: 165px;
  font-size: 14px;
}
.server-center__box:nth-child(3) {
  margin-bottom: 0;
}
.server-center__box2 {
  display: flex;
  align-self: center;
  align-items: center;
  min-width: 165px;
  font-size: 14px;
}
.server-center__icon {
  margin-right: 7px;
  fill: var(--color-secondary);
}
.server-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
.btn_copy_ip {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 12px;
  width: 230px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--color-secondary-light);
}
.btn_copy_ip span {
  margin-right: 5px;
  width: 195px;
  font-size: 12px;
  color: var(--color-dark) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn_play {
  width: 100%;
  height: 40px;
  font-size: 15px;
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .server-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .server-center__box2 {
    display: none;
  }
  .server-right {
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .server-left {
    margin-right: unset;
  }
  .server__name,
  .server__desc,
  .server__banner {
    width: 328px;
  }
  .server-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .server-center__box2 {
    display: none;
  }
  .server-right {
    flex-direction: column;
    justify-content: center;
  }
  .btn_copy_ip {
    margin-right: unset;
    margin-bottom: 20px;
    padding: 0 7px;
    width: 130px;
    height: 50px;
  }
  .btn_copy_ip span {
    width: 95px;
  }
  .btn_play {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .server {
    flex-direction: column;
    padding: 15px;
  }
  .server-left {
    justify-content: space-between;
    margin: 0 0 15px 0;
  }
  .server-left__box {
    display: none;
  }
  .server__name,
  .server__desc,
  .server__banner {
    width: 100%;
  }
  .server-left__box {
    margin-right: 15px;
  }
  .server__main {
    width: 100%;
  }
  .server-center {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 40px;
  }
  .server-center__box {
    margin-bottom: unset;
  }
  .server-center__box:nth-child(2) {
    justify-content: center;
  }
  .server-center__box:nth-child(3) {
    justify-content: flex-end;
  }
  .server-center__box2 {
    display: none;
  }
  .server-right {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
  .btn_copy_ip {
    margin-bottom: unset;
    margin-right: 15px;
    width: 100%;
    max-width: 50%;
    height: 50px;
  }
  .btn_copy_ip span {
    width: calc(100% - 20px);
  }
  .btn_play {
    width: unset;
    width: 100%;
    height: 50px;
  }
}
@media screen and (min-width: 320px) and (max-width: 559px) {
  .server-center {
    flex-direction: column;
    height: unset;
  }
  .server-center__box {
    margin-bottom: 10px;
  }
  .server-center__box:nth-child(2) {
    justify-content: flex-start;
  }
  .server-center__box:nth-child(3) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}
</style>
