import Api from "../apiService";

class serverRconsApi {
  index(serverId) {
    return Api.getRequest(
      `servers/${serverId}/server_rcon`,
      {},
      Api.authHeaders()
    );
  }

  update(serverId, data) {
    return Api.patchRequest(
      `servers/${serverId}/server_rcon`,
      data,
      Api.authHeaders()
    );
  }
}

export default new serverRconsApi();
