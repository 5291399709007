import axios from "axios";

class Api {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL + "/api/v1/";
    this.token = localStorage.getItem("token");
  }

  getRequest(path, params, headers) {
    return axios.get(this.methodPath(path), {
      params: params,
      headers: headers,
    });
  }

  postRequest(path, data, headers) {
    return axios.post(this.methodPath(path), data, { headers: headers });
  }

  patchRequest(path, data, headers) {
    return axios.patch(this.methodPath(path), data, { headers: headers });
  }

  authHeaders() {
    return {
      Authorization: `Basic ${this.token}`,
    };
  }

  authHeadersServerEdit() {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: `Basic ${this.token}`,
    };
  }

  methodPath(path) {
    return this.baseURL + path;
  }
}

export default new Api();
