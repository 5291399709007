<template>
  <div class="profile-content">
    <h3 class="content__heading">Мой профиль</h3>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "user-profile",
  extends: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.profile-content {
  display: flex;
  justify-content: center;
}
</style>
