<template>
  <section class="add_server">
    <div class="container add_server-container">
      <modal-window
        :is-modal-open="isModalOpen"
        @close="closeModal"
        @click="closeModal"
      >
        <approve-motd
          v-if="isModalOpen"
          :motd="motd"
          :server-claim-id="serverClaimId"
          @click.stop
        ></approve-motd>
      </modal-window>
      <div class="center-block">
        <h2 class="heading">Добавить сервер</h2>
        <span class="desc"
          >Укажите <b>IP-адрес</b> и <b>порт</b> вашего сервера</span
        >
        <form class="server_add-form" @submit.prevent>
          <base-error
            class="error__msg"
            :base-error="baseError"
            v-show="baseError.visible"
          />
          <base-input
            class="form__input"
            type="text"
            placeholder="IP-адрес сервера:порт"
            v-model="address"
            @input="onInputChange"
          />
          <span class="example"
            >Например: 1.2.3.4:56789, example.net, example.online:56789</span
          >
          <span></span>
          <base-recaptcha />
          <base-error-container
            class="error-box"
            :error-container-msg="errorContainerMsg"
            v-show="errorContainerMsg.visible"
          />
          <base-button class="form__btn" type="submit" @click="postData"
            >Далее</base-button
          >
        </form>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import ModalWindow from "@/components/ModalWindow";
import ApproveMotd from "@/components/ApproveMotd";

import { checkAuthenticationStatus } from "@/plugins/authUtils";
import serverClaimsApi from "../services/api/serverClaimsApi";

export default {
  name: "AddServerPage",
  extends: {},
  props: {},
  data() {
    return {
      address: "",
      motd: "",
      isModalOpen: false,
      allowedChars: true,
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      baseError: { visible: false, text: "Возникла ошибка" },
      serverClaimId: -1,
    };
  },
  computed: {},
  components: {
    ModalWindow,
    ApproveMotd,
  },
  watch: {},
  methods: {
    onInputChange(event) {
      this.allowedChars = true;
      // Проверяем введенные символы с помощью регулярного выражения
      const regex = /^[a-zA-Z0-9.:-]+$/;
      if (regex.test(event.target.value)) {
        this.address = event.target.value;
      } else {
        this.allowedChars = false;
      }
    },
    async postData() {
      if (!checkAuthenticationStatus()) {
        this.$store.state.loginError.text =
          "Чтобы добавить сервер, вам необходимо зарегистрироваться";
        this.$store.state.loginError.returnToPage = "server-add";
        this.$router.push("login");
        return;
      }
      if (!this.address) {
        this.allowedChars = true;
        this.baseError.visible = true;
        this.baseError.text = "IP адрес не должен быть пустым";
        return;
      }
      if (this.allowedChars != true) {
        this.address = "";
        this.allowedChars = true;
        this.baseError.visible = true;
        this.baseError.text = "Были введены недопустимые символы";
        return;
      }
      let [ip, port] = this.address.split(":");
      if (!port) {
        port = "25565";
      }

      await serverClaimsApi
        .createClaim({
          ip: ip,
          port: port,
          "g-recaptcha-response-data": this.$store.state.captchaResponse,
        })
        .then((response) => {
          this.motd = response.data.result.code;
          this.serverClaimId = response.data.result.id;
          this.errorContainerMsg.visible = false;
          this.baseError.visible = false;
          this.allowedChars = true;
          // Открываем модальное окно
          this.isModalOpen = true;
          document.querySelector("body").classList.add("scroll-lock");
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status == 401) {
            this.errorContainerMsg.text =
              "Сначала войдите или зарегистрируйтесь";
          } else if (e.response.status == 422) {
            this.errorContainerMsg.text = e.response.data.errors;
          } else if (e.response.status == 500) {
            this.errorContainerMsg.text =
              "что-то пошло не так, пожалуйста обратитесь к администратору";
          }
        });
    },
    closeModal() {
      this.isModalOpen = false;
      document.querySelector("body").classList.remove("scroll-lock");
    },
  },
  beforeCreate() {},
  mounted() {
    // Загрузка скрипта ReCaptcha ----->
    const insertScript = document.createElement("script");
    insertScript.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    document.body.appendChild(insertScript);
    // <-----
  },
};
</script>

<style scoped>
.add_server {
  background-color: var(--color-light);
}
.center-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading {
  margin-bottom: 30px;
  color: var(--color-dark);
}
.desc,
.example {
  margin-bottom: 15px;
  color: var(--color-dark);
  text-align: center;
}
.example {
  font-size: 14px;
}
.server_add-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form__input {
  margin-bottom: 15px;
  width: 400px;
  height: 40px;
}
.form__btn {
  width: 290px;
  height: 40px;
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  .form__input {
    max-width: 290px;
  }
}
</style>
