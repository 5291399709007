<template>
  <div class="user_servers-content">
    <h3 class="heading">Мои сервера</h3>
    <base-loader v-show="dataIsLoading" />
    <ul
      class="server-list"
      v-if="currentUserServers.length > 0 && !dataIsLoading"
    >
      <li
        class="server__item"
        v-for="server in currentUserServers"
        :key="server.id"
      >
        <div class="server-left">
          <strong class="server__name">{{ server.name }}</strong>
          <span
            class="server__address"
            v-if="server.port != 25565 && server.port != null"
            >{{ server.ip }}:{{ server.port }}</span
          >
          <span class="server__address" v-else>{{ server.ip }}</span>
        </div>
        <div class="server-center">
          <!-- Версия -->
          <div class="server-center__box">
            <svg
              class="server__icon"
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="22"
              height="22"
            >
              <path
                d="m16.298,8.288l1.404,1.425-5.793,5.707c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701Zm7.702,3.712c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
              />
            </svg>

            <span
              class="server__version"
              v-if="
                server.version_to != null &&
                server.version_to != '' &&
                server.version_from != server.version_to
              "
              >{{ server.version_from }} - {{ server.version_to }}</span
            >
            <span class="server__version" v-else>{{
              server.version_from
            }}</span>
          </div>
          <!-- Онлайн -->
          <div class="server-center__box">
            <svg
              class="server__icon"
              id="Layer_1"
              height="22"
              viewBox="0 0 24 24"
              width="22"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
            >
              <path
                d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 14a5.006 5.006 0 0 0 -5-5h-5a5.006 5.006 0 0 0 -5 5v4h2v-4a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v4h2zm2.5-11a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm6.5 14a5.006 5.006 0 0 0 -5-5h-4v2h4a3 3 0 0 1 3 3v4h2z"
              />
            </svg>
            <span
              class="server__online"
              v-if="server.status != 'offline' && server.status != null"
              >{{ server.current_online }} / {{ server.max_online }}</span
            >
            <span class="server__online" v-else>Офлайн</span>
          </div>
          <!-- Рейтинг -->
          <div class="server-center__box">
            <svg
              class="server__icon"
              xmlns="http://www.w3.org/2000/svg"
              id="Outline"
              viewBox="0 0 24 24"
              width="22"
              height="22"
            >
              <path
                d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z"
              />
            </svg>

            <span class="server__rating">{{ server.rating }}</span>
          </div>
        </div>
        <div class="server-right">
          <base-button
            class="edit__btn"
            role="link"
            @click="goToServerSettings(server.id)"
            >Настроить</base-button
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
import serversApi from "../services/api/serversApi";

export default {
  name: "user-servers",
  extends: {},
  props: {},
  data() {
    return {
      currentUserServers: [],
      dataIsLoading: true,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async getUserServers() {
      const userId = localStorage.getItem("userId");

      await serversApi
        .index({ user_id: userId })
        .then((response) => {
          this.currentUserServers = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении запрашиваемых данных с сервера", e);
        });
    },
    goToServerSettings(serverId) {
      this.$router.push({
        name: "ServerSettings",
        params: { id: serverId },
      });
    },
  },
  beforeCreate() {},
  mounted() {
    this.getUserServers();
  },
};
</script>

<style scoped>
.user_servers-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading {
  margin-bottom: 30px;
}
.server-list {
  width: 100%;
}
.server__item {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  min-height: 150px;
  border: 1px solid var(--color-dark);
  border-radius: 10px;
}
.server__item:not(:last-child) {
  margin-bottom: 10px;
}
.server-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
}
.server__name {
  margin-bottom: 15px;
  font-size: 20px;
  color: var(--color-dark);
}
.server__address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.server-center__box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-width: 190px;
  font-size: 14px;
}
.server-center__box:nth-child(3) {
  margin-bottom: 0;
}
.server__icon {
  margin-right: 7px;
  fill: var(--color-secondary);
}
.server-right {
  display: flex;
  align-items: center;
}
.edit__btn {
  padding: 5px 10px;
  width: unset;
  font-size: 14px;
}
</style>
