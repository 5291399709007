<template>
  <div class="menu">
    <div class="menu-backdrop" v-if="isMenuOpen"></div>
    <transition name="slide">
      <div v-if="isMenuOpen" class="menu-panel">
        <div
          class="main-menu"
          v-if="!this.$store.state.isMobileMenuBtnEnterPressed"
        >
          <nav class="menu-nav">
            <ul class="menu-list">
              <li class="menu__item">
                <base-button
                  class="btn btn_add"
                  role="link"
                  @click="btnAddServerPressed"
                >
                  Добавить сервер
                </base-button>
              </li>
              <li class="menu__item" v-if="!isAuth">
                <base-button
                  class="btn btn_login"
                  role="link"
                  @click="btnEnterPressed"
                >
                  Войти
                </base-button>
              </li>
              <li class="menu__item" v-else>
                <base-button
                  class="btn btn_login"
                  role="link"
                  @click="btnProfilePressed"
                >
                  Профиль
                </base-button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="auth-forms" v-else>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "mobile-menu",
  extends: {},
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAuth: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    authTest() {
      const token = localStorage.getItem("token");
      if (token) {
        this.isAuth = true;
      }
    },
    btnAddServerPressed() {
      this.$router.push({ name: "ServerAdd" });
      this.$emit("close-menu");
    },
    btnEnterPressed() {
      this.$store.state.isMobileMenuBtnEnterPressed = true;
    },
    btnProfilePressed() {
      this.$router.push({ name: "Account" });
      this.$emit("close-menu");
    },
  },
  beforeCreate() {},
  mounted() {
    this.authTest();
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
.menu-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 100px;
  left: 0;
  cursor: pointer;
}
.menu-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: var(--color-dark);
  position: fixed;
  left: 0;
  top: 100px;
  height: 100%;
  z-index: 999;
  width: 100%;
  height: 100vh;
}
.main-menu {
  padding-top: 100px;
}
.btn_add {
  margin-bottom: 20px;
}
</style>
