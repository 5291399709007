<template>
  <div class="approve-container">
    <div class="loading__box" v-if="isLoading">
      <h3 class="approve__heading">Подтверждение</h3>
      <span class="approve__desc approve__desc--loading">
        Для публикации сервера вам необходимо подтвердить на него права
      </span>
      <base-loader class="loader" />
      <p class="approve__info">
        Проверка может занять несколько минут, не закрывайте окно
      </p>
    </div>
    <div class="approve__box" v-else>
      <h3 class="approve__heading">Подтверждение</h3>
      <span class="approve__desc">
        Для публикации сервера вам необходимо подтвердить на него права
      </span>
      <!-- Параграф по умолчанию -->
      <div class="approve-info success" v-if="errorVisible == false">
        <p class="info-box">
          <span>Измените MOTD сервера так,</span>
          <span>чтобы он содержал эту строку:</span>
        </p>
        <button class="btn__copy_ip" @click="copyToClipboard">
          <base-tooltip
            :tooltip="tooltip"
            v-show="tooltip.visible"
          ></base-tooltip>
          <span>{{ this.motd }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="16"
            height="17"
          >
            <path
              d="m19,0h-6c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5Zm3,11c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h6c1.654,0,3,1.346,3,3v6Zm-6,8c0,2.757-2.243,5-5,5h-6c-2.757,0-5-2.243-5-5v-6c0-2.757,2.243-5,5-5,.553,0,1,.448,1,1s-.447,1-1,1c-1.654,0-3,1.346-3,3v6c0,1.654,1.346,3,3,3h6c1.654,0,3-1.346,3-3,0-.552.447-1,1-1s1,.448,1,1Z"
            />
          </svg>
        </button>
      </div>
      <!-- Параграф с ошибкой -->
      <div class="approve-info failed" v-else>
        MOTD должен содержать:
        <button class="btn__copy_ip" @click="copyToClipboard">
          <base-tooltip
            :tooltip="tooltip"
            v-show="tooltip.visible"
          ></base-tooltip>
          <span>{{ this.motd }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="16"
            height="17"
          >
            <path
              d="m19,0h-6c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5Zm3,11c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h6c1.654,0,3,1.346,3,3v6Zm-6,8c0,2.757-2.243,5-5,5h-6c-2.757,0-5-2.243-5-5v-6c0-2.757,2.243-5,5-5,.553,0,1,.448,1,1s-.447,1-1,1c-1.654,0-3,1.346-3,3v6c0,1.654,1.346,3,3,3h6c1.654,0,3-1.346,3-3,0-.552.447-1,1-1s1,.448,1,1Z"
            />
          </svg>
        </button>
        <span class="info">Убедитесь, что вы перезапустили сервер</span>
      </div>
      <p class="approve__info">
        Если у вас возникли трудности,
        <a class="info__link">посмотрите справку</a>
      </p>
      <base-button class="submit_btn" @click="postRequest"
        >Подтвердить</base-button
      >
    </div>
  </div>
</template>

<script type="text/javascript">
import serverClaimsApi from "../services/api/serverClaimsApi";

export default {
  name: "approve-motd",
  extends: {},
  props: {
    motd: {
      type: String,
      required: true,
    },
    serverClaimId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      errorVisible: false,
      tooltip: { visible: false, text: "IP скопирован" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async postRequest() {
      this.isLoading = true;

      await serverClaimsApi
        .create(this.serverClaimId, { id: this.serverClaimId })
        .then(() => {
          this.$emit("close-modal");
          this.this.$router.push({
            name: "ServerCreate",
            query: { server_claim_id: this.serverClaimId },
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.errorVisible = true;
        });
    },
    async copyToClipboard() {
      const textToCopy = this.motd;

      try {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        this.hideTooltip();
      } catch (e) {
        console.error("Ошибка при копировании текста в буфер обмена:", e);
      }
    },
    hideTooltip() {
      this.tooltip.visible = true;
      setTimeout(() => {
        this.tooltip.visible = false;
      }, 5000);
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.approve-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 50px;
  background-color: var(--color-dark);
  color: var(--color-light);
  border-radius: 30px;
}
.loading__box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.approve__box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.approve__heading {
  margin-bottom: 30px;
  font-weight: bold;
  color: var(--color-secondary);
}
.approve__desc,
.approve-info {
  margin-bottom: 30px;
  width: 290px;
  line-height: 1.2;
  text-align: center;
}
.approve__desc--loading {
  margin-bottom: 0;
}
.success {
  margin-bottom: 40px;
}
.approve__info {
  margin-bottom: 30px;
  width: 290px;
  text-align: center;
}
.info__link {
  color: var(--color-primary);
}
.info-box {
  display: flex;
  flex-direction: column;
}
.btn__copy_ip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0 auto;
  padding: 10px 15px;
  width: unset;
  border-radius: 10px;
  background-color: var(--color-secondary-light);
}
.btn__copy_ip span {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 20px;
}
.info {
  display: block;
  margin-top: 10px;
}
.failed {
  color: var(--color-error);
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .approve-container {
    height: 100vh;
    border-radius: 0;
  }
}
</style>
