<template>
  <div class="wrapper">
    <div class="container account-container">
      <aside class="account-sidebar">
        <div class="sidebar-block">
          <nav class="sidebar__menu">
            <ul class="menu__list">
              <li class="menu__item">
                <base-button
                  class="btn menu__btn"
                  :class="{ active__btn: isActiveBtn(1) }"
                  @click="changeContent(1)"
                  >Мой профиль</base-button
                >
              </li>
              <li class="menu__item">
                <base-button
                  class="btn menu__btn"
                  :class="{ active__btn: isActiveBtn(2) }"
                  @click="changeContent(2)"
                  >Мои сервера</base-button
                >
              </li>
              <li class="menu__item">
                <base-button
                  class="btn menu__btn"
                  :class="{ active__btn: isActiveBtn(3) }"
                  @click="changeContent(3)"
                  >Настройки аккаунта</base-button
                >
              </li>
            </ul>
          </nav>
          <base-button class="btn exit__btn" @click="logOut">
            Выйти из аккаунта
          </base-button>
        </div>
      </aside>
      <div class="account-content">
        <user-profile v-if="currentContent == 1" />
        <user-servers v-else-if="currentContent == 2" />
        <user-settings v-else-if="currentContent == 3" />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import UserProfile from "@/components/UserProfile";
import UserServers from "@/components/UserServers";
import UserSettings from "@/components/UserSettings";

export default {
  name: "AccountPage",
  extends: {},
  props: {},
  data() {
    return {
      currentContent: 1,
    };
  },
  computed: {},
  components: {
    UserProfile,
    UserServers,
    UserSettings,
  },
  watch: {},
  methods: {
    changeContent(content) {
      this.currentContent = content;
    },
    isActiveBtn(content) {
      return this.currentContent == content;
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("exp");
      this.$router.push({ name: "Home" }).then(() => {
        location.reload();
      });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.wrapper {
  background-color: #f0f0f0;
}
.account-container {
  display: flex;
  min-height: calc(100vh - 350px);
}
.account-sidebar {
  margin-right: 30px;
  padding: 30px;
  min-height: 450px;
  max-height: calc(100vh - 450px);
  background-color: #fff;
  border-radius: 10px;
}
.sidebar-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.menu__item {
  margin-bottom: 10px;
}
.menu__btn {
  background-color: transparent;
}
.exit__btn {
  margin-top: auto;
  background-color: unset;
}
.account-content {
  padding: 30px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.active__btn {
  outline: 3px solid var(--color-secondary);
}
</style>
