<template>
  <div class="container password_recovery-content">
    <h2 class="content__heading">Восстановление пароля</h2>
    <form class="content__form" @submit.prevent="patchRecoveryPassword">
      <!-- <label for="input1" class="input__desc">Логин</label>
      <base-input
        id="input1"
        class="input form__input"
        type="text"
        placeholder="Логин"
        disabled
        v-model="login"
      /> -->
      <label for="input2" class="input__desc">Новый пароль</label>
      <base-input
        id="input2"
        class="input form__input"
        placeholder="Новый пароль"
        type="password"
        autocomplete="new-password"
        v-model="newPassword"
      />
      <label for="input3" class="input__desc">Пароль ещё раз</label>
      <base-input
        id="input3"
        class="input form__input"
        placeholder="Пароль ещё раз"
        type="password"
        autocomplete="new-password"
        v-model="newPasswordConfirmation"
      />
      <base-recaptcha />
      <base-error-container
        class="error-box"
        :error-container-msg="errorContainerMsg"
        v-show="errorContainerMsg.visible"
      />
      <base-button class="btn submit__btn" type="submit"
        >Изменить пароль</base-button
      >
    </form>
  </div>
</template>

<script type="text/javascript">
import usersApi from "../services/api/usersApi";
import { useToast } from "vue-toastification";

export default {
  name: "PasswordRecoveryPage",
  extends: {},
  props: {},
  data() {
    return {
      login: "",
      recoveryToken: "",
      newPassword: "",
      newPasswordConfirmation: "",
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async patchRecoveryPassword() {
      await usersApi
        .recoveryPassword({
          login: this.login,
          recovery_token: this.recoveryToken,
          password: this.newPassword,
          password_confirmation: this.newPasswordConfirmation,
          "g-recaptcha-response-data": this.$store.state.captchaResponse,
        })
        .then(() => {
          this.errorContainerMsg.visible = false;
          this.$router.push({ name: "Login" }).then(() => {
            useToast().success("Пароль успешно изменён");
          });
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  created() {
    this.login = this.$route.query.login;
    this.recoveryToken = this.$route.query.recovery_token;
  },
  mounted() {},
};
</script>

<style scoped>
.password_recovery-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc {
  margin-bottom: 10px;
}
.input {
  margin-bottom: 20px;
  width: 290px;
  height: 40px;
}
.btn {
  width: 290px;
}
</style>
