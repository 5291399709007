<template>
  <div class="wrapper">
    <div class="container login-container">
      <login-form
        :base-error-text="baseError.text"
        :login-page-style="loginPageStyle"
        @show-reg-form="showRegForm"
        v-if="loginVisible"
      />
      <registration-form
        :login-page-style="loginPageStyle"
        @show-login-form="showLoginForm"
        v-else
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import LoginForm from "@/components/LoginForm";
import RegistrationForm from "@/components/RegistrationForm";

export default {
  name: "LoginPage",
  extends: {},
  props: {},
  data() {
    return {
      loginVisible: true,
      loginPageStyle: true,
      baseError: { visible: false, text: "Возникла ошибка" },
    };
  },
  computed: {},
  components: { LoginForm, RegistrationForm },
  watch: {},
  methods: {
    showRegForm() {
      this.loginVisible = false;
    },
    showLoginForm() {
      this.loginVisible = true;
    },
  },
  beforeCreate() {},
  created() {
    localStorage.clear();
    if (this.$store.state.loginError != "") {
      this.baseError.text = this.$store.state.loginError.text;
      this.baseError.visible = true;
    }
  },
  mounted() {},
};
</script>

<style scoped>
.wrapper {
  background-color: #f0f0f0;
}
.login-container {
  display: flex;
  justify-content: center;
}
</style>
