<template>
  <div class="vote_commands-content">
    <h3 class="content__heading">Команды</h3>
    <base-loader v-if="dataIsLoading" />
    <table class="content-table" v-else-if="serverRconRequests.length > 0">
      <thead>
        <tr>
          <th>id</th>
          <th>response_body</th>
          <th>request_body</th>
          <th>status</th>
          <th>sent_at</th>
          <th>server_vote_id</th>
          <th>user_id</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="rconRequestItem in serverRconRequests"
          :key="rconRequestItem.id"
        >
          <td class="column1">
            {{ rconRequestItem.id }}
          </td>
          <td class="column2">
            {{ rconRequestItem.response_body }}
          </td>
          <td class="column3">
            {{ rconRequestItem.request_body }}
          </td>
          <td
            class="column4"
            :style="{ color: checkStatusColor(rconRequestItem.status) }"
          >
            {{ rconRequestItem.status }}
          </td>
          <td class="column5">
            {{ trueDate(rconRequestItem.sent_at) }}
          </td>
          <td class="column6">
            {{ rconRequestItem.server_vote_id }}
          </td>
          <td class="column7">
            {{ rconRequestItem.user_id }}
          </td>
        </tr>
      </tbody>
    </table>
    <p class="temp_stub" v-else>Здесь пока ничего нет</p>
  </div>
</template>

<script type="text/javascript">
import { parseDate } from "@/plugins/parseDateUtils";
import rconRequestsApi from "../services/api/rconRequestsApi";

export default {
  name: "vote-commands",
  extends: {},
  props: {
    serverId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataIsLoading: true,
      serverRconRequests: {},
      pageNumber: 1,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    trueDate(date) {
      return parseDate(date);
    },
    checkStatusColor(statusText) {
      if (statusText == "delivered") {
        return "green";
      } else if (statusText == "failed") {
        return "red";
      } else {
        return "orange";
      }
    },
    async getRconRequests(pageNumber) {
      await rconRequestsApi
        .index(this.serverId, pageNumber)
        .then((response) => {
          this.serverRconRequests = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 404) {
            console.log("Страница не найдена");
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {
    this.getRconRequests(this.pageNumber);
  },
};
</script>

<style scoped>
.vote_commands-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content-table {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  border-collapse: separate;
}
.content-table thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #d8d8d8;
  font-size: 14px;
  border-top: 1px solid #f3f3f3;
}
.content-table tr th:first-child,
.content-table tr td:first-child {
  border-left: 1px solid #f3f3f3;
}
.content-table tr th:last-child,
.content-table tr td:last-child {
  border-right: 1px solid #f3f3f3;
}
.content-table thead tr th:first-child {
  border-radius: 20px 0 0 0;
}
.content-table thead tr th:last-child {
  border-radius: 0 20px 0 0;
}
.content-table tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.content-table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.content-table tbody tr:last-child td {
  border-bottom: 1px solid #f3f3f3;
}
.content-table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}
.content-table tbody tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}
</style>
