<template>
  <input
    class="input"
    type="text"
    placeholder="sample text"
    :value="modelValue"
    @input="updateInput"
  />
</template>

<script type="text/javascript">
export default {
  name: "base-input",
  extends: {},
  props: {
    modelValue: [String, Number],
  },
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.input {
  font-size: 16px;
  line-height: 1;
  background-color: var(--color-light);
  border: 1px solid var(--color-input-border);
  border-radius: 20px;
  color: var(--color-dark);
  text-align: center;
}
.input::placeholder {
  font-size: 16px;
  line-height: 1;
  color: var(--color-dark);
  opacity: 0.5;
}
.input:focus {
  outline: 2px solid var(--color-primary);
}
</style>
