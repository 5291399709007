<template>
  <div class="vote_webhooks-content">
    <h3 class="content__heading">Вебхуки для голосов</h3>
    <base-loader v-if="dataIsLoading" />
    <form class="content__form" @submit.prevent v-else>
      <label for="input0" class="input__desc">Webhook URL</label>
      <base-input
        id="input0"
        class="input form__input"
        type="text"
        placeholder="Webhook URL"
        v-model="webhookURL"
      />
      <base-error-container
        class="error-box"
        :error-container-msg="errorContainerMsg"
        v-show="errorContainerMsg.visible"
      />
      <base-button
        class="btn submit__btn"
        type="submit"
        @click="patchVoteWebhook"
        >Сохранить</base-button
      >
    </form>
  </div>
</template>

<script type="text/javascript">
import serverSettingsApi from "../services/api/serverSettingsApi";
import { useToast } from "vue-toastification";

export default {
  name: "vote-webhooks",
  extends: {},
  props: {
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serverId: this.serverSettings.server_id,
      webhookURL: this.serverSettings.webhook_url,
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      dataIsLoading: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async patchVoteWebhook() {
      this.dataIsLoading = true;

      await serverSettingsApi
        .webhookUpdate(this.serverId, {
          server_id: this.serverId,
          webhook_url: this.webhookURL,
        })
        .then(() => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = false;
          useToast().success("Данные успешно сохранены");
        })
        .catch((e) => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.vote_webhooks-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc {
  margin-bottom: 10px;
}
.input {
  margin-bottom: 20px;
  width: 520px;
  height: 40px;
}
.error-box {
  align-self: center;
}
</style>
