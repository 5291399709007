import Api from "../apiService";

class rconRequestsApi {
  index(serverId, pageNumber) {
    return Api.getRequest(
      `servers/${serverId}/rcon_requests`,
      {
        page: pageNumber,
      },
      Api.authHeaders()
    );
  }
}

export default new rconRequestsApi();
