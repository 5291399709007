<template>
  <div class="vote_list-content">
    <h3 class="content__heading">Голоса</h3>
    <base-loader v-if="dataIsLoading" />
    <table class="content-table" v-else-if="serverVotes.length > 0">
      <thead>
        <tr>
          <th>Ник игрока</th>
          <th>Статус</th>
          <th>Создан</th>
          <th>Обновлен</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="voteItem in serverVotes" :key="voteItem.id">
          <td class="column1">
            {{ voteItem.player_name }}
          </td>
          <td
            class="column2"
            :style="{ color: checkStatusColor(voteItem.webhook_status) }"
          >
            {{ voteItem.webhook_status }}
          </td>
          <td class="column3">
            {{ trueDate(voteItem.created_at) }}
          </td>
          <td class="column4">
            {{ trueDate(voteItem.updated_at) }}
          </td>
        </tr>
      </tbody>
    </table>
    <p class="temp_stub" v-else>За сервер ещё никто не голосовал</p>
  </div>
</template>

<script type="text/javascript">
import serverVotesApi from "../services/api/serverVotesApi";
import { parseDate } from "@/plugins/parseDateUtils";

export default {
  name: "vote-list",
  extends: {},
  props: {
    serverId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataIsLoading: true,
      serverVotes: {},
      pageNumber: 1,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    trueDate(date) {
      return parseDate(date);
    },

    checkStatusColor(statusText) {
      if (statusText == "ok") {
        return "green";
      } else if (statusText == "not ok") {
        return "red";
      } else {
        return "orange";
      }
    },

    async getServerVotes(pageNumber) {
      await serverVotesApi
        .index(this.serverId, pageNumber)
        .then((response) => {
          this.serverVotes = response.data.result;
          this.dataIsLoading = false;
        })
        .catch((e) => {
          console.log("Ошибка при получении данных с сервера", e);
        });
    },
  },
  beforeCreate() {},
  mounted() {
    this.getServerVotes(this.pageNumber);
  },
};
</script>

<style scoped>
.vote_list-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content-table {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  border-collapse: separate;
}
.content-table thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #d8d8d8;
  font-size: 14px;
  border-top: 1px solid #f3f3f3;
}
.content-table tr th:first-child,
.content-table tr td:first-child {
  border-left: 1px solid #f3f3f3;
}
.content-table tr th:last-child,
.content-table tr td:last-child {
  border-right: 1px solid #f3f3f3;
}
.content-table thead tr th:first-child {
  border-radius: 20px 0 0 0;
}
.content-table thead tr th:last-child {
  border-radius: 0 20px 0 0;
}
.content-table tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.content-table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.content-table tbody tr:last-child td {
  border-bottom: 1px solid #f3f3f3;
}
.content-table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}
.content-table tbody tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}
</style>
