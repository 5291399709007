<template>
  <div class="toggle-box">
    <div
      class="toggle__btn btn__left"
      role="button"
      :class="{ active: isActiveBtn(1) }"
      @click="changeContent(1)"
    >
      <span class="toggle__text">{{ labelLeft }}</span>
    </div>
    <div
      class="toggle__btn btn__right"
      role="button"
      :class="{ active: isActiveBtn(2) }"
      @click="changeContent(2)"
    >
      <span class="toggle__text">{{ labelRight }}</span>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "base-big-toggle",
  extends: {},
  props: {
    labelLeft: {
      type: String,
      required: true,
    },
    labelRight: {
      type: String,
      required: true,
    },
  },
  data() {
    return { currentContent: 1 };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    changeContent(content) {
      this.currentContent = content;
      this.$emit("change-content", content);
    },
    isActiveBtn(content) {
      return this.currentContent == content;
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.toggle-box {
  position: relative;
  display: flex;
  width: 250px;
  height: 30px;
  border: 1px solid var(--color-input-border);
  border-radius: 30px;
}
.toggle-box:before {
  position: absolute;
  content: "";
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
  border: 0.5px solid var(--color-input-border);
}
.toggle__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: var(--color-light);
}
.btn__left {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.btn__right {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.active {
  background-color: var(--color-primary);
}
</style>
