<template>
  <div class="recaptcha-box">
    <div
      id="g-recaptcha"
      class="g-recaptcha"
      :data-sitekey="siteKey"
      data-callback="myRecaptchaMethod"
    ></div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "base-recaptcha",
  extends: {},
  props: {},
  data() {
    return { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    loadRecaptchaScript() {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    },
    myRecaptchaMethod: function (response) {
      this.$store.state.captchaResponse = response;
    },
  },
  beforeCreate() {},
  mounted() {
    this.loadRecaptchaScript();
    window.myRecaptchaMethod = this.myRecaptchaMethod;
  },
};
</script>

<style>
.g-recaptcha {
  margin-bottom: 20px;
  transform: scale(0.95);
}
</style>
