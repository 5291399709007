<template>
  <div class="discord_notifications-content">
    <h3 class="content__heading">Настройка оповещений Discord</h3>
    <base-loader v-if="dataIsLoading" />
    <form class="content__form" @submit.prevent v-else>
      <label for="input0" class="input__desc">Discord webhook URL</label>
      <base-input
        id="input0"
        class="input form__input"
        type="url"
        placeholder="Discord webhook URL"
        v-model="discordWebhookURL"
      ></base-input>
      <span class="textarea__desc">Discord webhook text</span>
      <textarea
        id="input1"
        class="textarea form__textarea"
        cols="50"
        rows="5"
        placeholder="Discord webhook text"
        v-model="discordWebhookText"
      ></textarea>
      <base-error-container
        class="error-box"
        :error-container-msg="errorContainerMsg"
        v-show="errorContainerMsg.visible"
      >
      </base-error-container>
      <base-button
        class="btn submit__btn"
        type="submit"
        @click="patchDiscordWebhook"
        >Сохранить</base-button
      >
    </form>
  </div>
</template>

<script type="text/javascript">
import serverSettingsApi from "../services/api/serverSettingsApi";
import { useToast } from "vue-toastification";

export default {
  name: "discord-notifications",
  extends: {},
  props: {
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serverId: this.serverSettings.server_id,
      discordWebhookURL: this.serverSettings.discord_webhook_url,
      discordWebhookText: this.serverSettings.discord_webhook_text,
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      dataIsLoading: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async patchDiscordWebhook() {
      this.dataIsLoading = true;
      await serverSettingsApi
        .discordWebhookUpdate(this.serverId, {
          server_id: this.serverId,
          discord_webhook_url: this.discordWebhookURL,
          discord_webhook_text: this.discordWebhookText,
        })
        .then(() => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = false;
          useToast().success("Данные успешно сохранены");
        })
        .catch((e) => {
          this.dataIsLoading = false;
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.discord_notifications-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc,
.textarea__desc {
  margin-bottom: 10px;
}
.input,
.textarea {
  width: 520px;
}
.input {
  margin-bottom: 20px;
  height: 40px;
}
.textarea {
  margin-bottom: 20px;
}
.error-box {
  align-self: center;
}
</style>
