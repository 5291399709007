export function checkAuthenticationStatus() {
  const token = localStorage.getItem("token");
  const exp = localStorage.getItem("exp");
  const expirationTime = new Date(parseInt(exp) * 1000);

  if (token && exp && expirationTime > new Date()) {
    return true;
  } else {
    return false;
  }
}
