export function parseDate(str) {
  const parsedDate = new Date(str);
  const date =
    parsedDate.getDate().toString().padStart(2, "0") +
    "/" +
    (parsedDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    parsedDate.getFullYear();
  const time =
    parsedDate.getHours().toString().padStart(2, "0") +
    ":" +
    parsedDate.getMinutes().toString().padStart(2, "0") +
    ":" +
    parsedDate.getSeconds().toString().padStart(2, "0");

  return date + " " + time;
}
