<template>
  <div class="container">
    <h3 class="info__heading">Подтвердите аккаунт</h3>
    <div class="registration-info">
      <p class="info__message">
        Вам отправлено письмо для активации аккаунта на
        <span class="email"> {{ email }} </span>
        <br />
        Перейдите по ссылке из письма для завершения регистрации
      </p>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "InfoPage",
  extends: {},
  props: {},
  data() {
    return {
      email: "",
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  created() {
    this.email = this.$route.query.email;
  },
  mounted() {},
};
</script>

<style scoped>
.info__heading {
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.registration-info {
  display: flex;
  justify-content: center;
}
.info__message {
  text-align: center;
  line-height: 1.5;
  color: var(--color-dark);
}
.email {
  color: var(--color-primary);
  font-weight: bold;
}
</style>
