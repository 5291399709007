import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/style/normalize.css";
import "./assets/style/index.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import components from "@/components/UI";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

Sentry.init({
  app,
  dsn: "https://d38a5264a35dccb35c2985af9f5ef491@o4506132506542080.ingest.sentry.io/4506161567367168",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/minehub\.top\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const options = {
  timeout: 5000,
  position: POSITION.TOP_RIGHT,
  toastClassName: "vt_toast_style",
  closeButtonClassName: "vt_close_btn_class",
};

app.use(Toast, options).use(store).use(router).mount("#app");
