import Api from "../apiService";

class usersApi {
  show(userId) {
    return Api.getRequest(`users/${userId}`, {}, Api.authHeaders());
  }

  create(data) {
    return Api.postRequest(`users`, data, {});
  }

  sendRecoveryToken(data) {
    return Api.postRequest(`users/send_recovery_token`, data, {});
  }

  changePassword(data) {
    return Api.patchRequest(`users/change_password`, data, Api.authHeaders());
  }

  recoveryPassword(data) {
    return Api.patchRequest(`users/recovery_password`, data, {});
  }
}

export default new usersApi();
