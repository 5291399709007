<template>
  <button class="btn">
    <slot />
  </button>
</template>

<script type="text/javascript">
export default {
  name: "base-button",
  extends: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style>
.btn {
  width: 190px;
  height: 40px;
  background-color: var(--color-primary);
  border-radius: 10px;
  font-size: 16px;
  line-height: 1;
  color: var(--color-dark);
}
.btn:focus {
  outline: 3px solid var(--color-secondary);
}
.btn:active {
  transform: translateY(2px);
}
</style>
