<template>
  <div class="container vote-container">
    <h2 class="vote__heading">Голосование</h2>
    <span class="left__desc">Ваш никнейм в игре:</span>
    <base-error
      class="error_msg"
      :base-error="baseError"
      v-show="baseError.visible"
    />
    <base-input
      class="input"
      placeholder="Никнейм"
      type="text"
      v-model="playerName"
    />
    <base-recaptcha />
    <base-error-container
      class="error-box"
      :error-container-msg="errorContainerMsg"
      v-show="errorContainerMsg.visible"
    />
    <base-button class="vote__btn" @click="checkPlayerNameInput"
      >Проголосовать</base-button
    >
  </div>
</template>

<script type="text/javascript">
import usersApi from "../services/api/usersApi";
import serverVotesApi from "../services/api/serverVotesApi";
import { useToast } from "vue-toastification";
import { checkAuthenticationStatus } from "@/plugins/authUtils";

export default {
  name: "VotePage",
  extends: {},
  props: {},
  data() {
    return {
      serverId: 0,
      playerName: "",
      baseError: { visible: false, text: "" },
      errorContainerMsg: { visible: false, text: "" },
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    checkPlayerNameInput() {
      if (this.playerName != "") {
        this.baseError.visible = false;
        this.sendVote();
      } else {
        this.errorContainerMsg.visible = false;
        this.baseError.visible = true;
        this.baseError.text = "Поле не может быть пустым";
      }
    },
    async getLastUserName() {
      const userId = localStorage.getItem("userId");

      await usersApi
        .show(userId)
        .then((response) => {
          this.playerName = response.data.result.last_player_name;
        })
        .catch((e) => {
          console.log(
            "Ошибка при получении последнего имени пользователя с сервера:",
            e
          );
        });
    },
    async sendVote() {
      if (!checkAuthenticationStatus()) {
        this.$store.state.loginError.text =
          "Чтобы проголосовать за сервер, вам необходимо зарегистрироваться";
        this.$store.state.loginError.returnToPage = "vote/" + this.serverId;
        console.log(this.$store.state.loginError.returnToPage);
        this.$router.push({
          name: "Login",
        });
        return;
      }
      this.errorContainerMsg.visible = false;

      await serverVotesApi
        .create(this.serverId, {
          server_id: this.serverId,
          player_name: this.playerName,
          "g-recaptcha-response-data": this.$store.state.captchaResponse,
        })
        .then(() => {
          useToast().success("Вы проголосовали за сервер");
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  created() {
    this.serverId = this.$route.params.id;
    this.getLastUserName();
  },
  mounted() {},
};
</script>

<style scoped>
.vote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vote__heading {
  margin-bottom: 50px;
  font-size: 26px;
}
.left__desc {
  margin-bottom: 10px;
}
.input {
  width: 290px;
  height: 40px;
  margin-bottom: 20px;
}
.vote__btn {
  width: 290px;
}
</style>
