<template>
  <div class="container password_change-content">
    <div class="content-center" v-if="!isPasswordChanged">
      <h2 class="content__heading">Смена пароля</h2>
      <form class="content__form" @submit.prevent="patchPassword">
        <label for="input1" class="input__desc">Текущий пароль</label>
        <base-input
          id="input1"
          class="input form__input"
          placeholder="Текущий пароль"
          type="password"
          autocomplete="current-password"
          v-model="currentPassword"
        />
        <label for="input2" class="input__desc">Новый пароль</label>
        <base-input
          id="input2"
          class="input form__input"
          placeholder="Новый пароль"
          type="password"
          autocomplete="new-password"
          v-model="newPassword"
        />
        <label for="input3" class="input__desc">Пароль ещё раз</label>
        <base-input
          id="input3"
          class="input form__input"
          placeholder="Пароль ещё раз"
          type="password"
          autocomplete="new-password"
          v-model="newPasswordConfirmation"
        />
        <base-error-container
          class="error-box"
          :error-container-msg="errorContainerMsg"
          v-show="errorContainerMsg.visible"
        />
        <base-button class="btn submit__btn" type="submit"
          >Изменить пароль</base-button
        >
      </form>
    </div>
    <div class="content-center" v-else>
      <p class="success__desc">Пароль успешно изменён!</p>
      <base-button
        class="btn"
        role="link"
        @click="this.$router.push({ name: 'Account' })"
        >В личный кабинет</base-button
      >
    </div>
  </div>
</template>

<script type="text/javascript">
import usersApi from "../services/api/usersApi";

export default {
  name: "PasswordChangePage",
  extends: {},
  props: {},
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      errorContainerMsg: { visible: false, text: "Возникла ошибка" },
      isPasswordChanged: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  methods: {
    async patchPassword() {
      await usersApi
        .changePassword({
          current_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.newPasswordConfirmation,
        })
        .then(() => {
          this.errorContainerMsg.visible = false;
          this.isPasswordChanged = true;
        })
        .catch((e) => {
          this.errorContainerMsg.visible = true;
          if (e.response.status != 200) {
            this.errorContainerMsg.text = e.response.data.errors;
          }
        });
    },
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content__heading {
  margin-bottom: 50px;
}
.content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input__desc {
  margin-bottom: 10px;
}
.input {
  margin-bottom: 20px;
  width: 290px;
  height: 40px;
}
.btn {
  width: 290px;
}
.error-box {
  align-self: center;
}
.success__desc {
  margin-bottom: 10px;
  width: 290px;
  text-align: center;
}
</style>
