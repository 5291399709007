<template>
  <div>
    <div v-if="servers.length > 0">
      <ul class="column_titles__list">
        <li class="column_titles__item server_position">
          № <span class="server_name">Сервер</span>
        </li>
        <li class="column_titles__item server_version">Версия</li>
        <li class="column_titles__item server_online">Онлайн</li>
        <li class="column_titles__item server_rating">Рейтинг</li>
        <li class="column_titles__item server_ip"></li>
      </ul>
      <ul class="server-list">
        <server-item
          class="server-item"
          v-for="(server, i) in servers"
          :server="server"
          :key="server.id"
          :position="i"
        />
      </ul>
    </div>
    <span class="temp_stub" v-else> Список серверов пуст </span>
  </div>
</template>

<script type="text/javascript">
import ServerItem from "@/components/ServerItem";

export default {
  name: "server-list",
  extends: {},
  props: {
    servers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  components: { ServerItem },
  watch: {},
  methods: {},
  beforeCreate() {},
  mounted() {},
};
</script>

<style scoped>
.column_titles__list {
  display: none;
  justify-content: space-between;
  margin-bottom: 15px;
}
.column_titles__item {
  font-size: 14px;
  color: var(--color-secondary);
}
.column_titles__item:nth-child(1) {
  padding-left: 30px;
  width: 474px;
}
.column_titles__item:nth-child(2),
.column_titles__item:nth-child(3),
.column_titles__item:nth-child(4) {
  width: 165px;
}
.server_name {
  margin-left: 20px;
}
.column_titles__item:nth-child(5) {
  width: 250px;
}
.server-item:not(:last-child) {
  margin-bottom: 10px;
}
.temp_stub {
  display: inline-block;
  margin-top: 35px;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
}
@media screen and (min-width: 1280px) {
  .column_titles__list {
    display: flex;
  }
}
</style>
